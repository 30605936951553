const state = {

  guest:{
      first_name : null,
      last_name  : null,
      telephone  : null,
      email      : null
  },

};

const getters = {
    bookingGuest  : (state)=>state.guest,
};

const actions = {

};

const mutations = {

    setGuest(state,keyvalue){
        state.guest[keyvalue.key] = keyvalue.value
    },

};

export default {
    state,
    getters,
    actions,
    mutations,
};

