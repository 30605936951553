import {Orders} from "@/Libary/Orders/Orders";
import Vue from "vue";
import _ from "underscore";
import axios from "axios";
import router from "@/router";
import {Favorites} from "@/Libary/User/Favorites";
import CDV from "@/Libary/Cordova/CDV";

const state = {
    table       : null,
    billSplit   : {},
    order: (localStorage.getItem('vuex') && localStorage.getItem('vuex').order)?JSON.parse(localStorage.getItem('vuex')).order.order:{
        points_payment:null,
        addingChanged:false,
        adding:null,
        uuid:null,
        id:null,
        basketHasOOS:false,
        items:[],
    },

};

const getters = {
    table         : (state) => state.table,
    order         : (state) => state.order,
    billSplit     : (state) => state.billSplit,
    addingChanged : (state) => state.order.addingChanged,

    billSplitPersonItemCount:(state)=>{
        let counts = {};
        _.each(state.billSplit,(personSelected,index)=>{
            let attributes = _.filter(personSelected, (item)=>{
                return (item)
            });
            counts[index] = _.size(attributes);
        });
        return counts
    },
    orderId : (state) => state.order.id,
    orderUuid : (state) => state.order.uuid,
    points_payment: (state)=>state.order.points_payment,
    adding : (state) => state.order.adding,
    basketHasOOS: (state) => state.order.basketHasOOS,
    orderQTYsByPLU: (state) => (uuid) =>{

        let qtys = {};
        _.each(state.order.items, (item)=>{
            if(uuid && item.uuid===uuid) return;
            if(!qtys[item.pos_id]) qtys[item.pos_id] = 0;
            qtys[item.pos_id] += item.qty;

            _.each(item.modifiers, (modG)=>{
                _.each(modG.modifiers, (mod)=>{
                    if(!qtys[mod.pos_id]) qtys[mod.pos_id] = 0;
                    qtys[mod.pos_id] += mod.qty;
                });
            });
        });

        return qtys;

    },
    orderItemCount: (state) => {
        let count = 0;
        _.each(state.order.items, (item)=>{
            count += item.qty;
        });
        return count;
    },
    orderTotal: (state) =>  _.reduce(state.order.items, (item, price)=>{

        let modPrice =  (price.display_name!=='Half & Half Pizza')?price.price:0;

        if(price.display_name==='Half & Half Pizza'){
            _.each(price.modifiers,(modG)=>{
                _.each(modG.modifiers,(mod)=>{
                    if(mod.price>modPrice) modPrice = mod.price;
                });
            });
        }
        _.each(price.modifiers,(mod)=>{
            _.each(mod.modifiers,(m)=> {
                if(!m.parent && price.display_name==='Half & Half Pizza') return;
                modPrice = (modPrice + (m.price*m.qty));
            });
        });

        return (price)?(item +  (price.qty*(modPrice))):0;
    }, 0),

};

const actions = {

    async sameAgain({commit}){

        await axios.get("/site/"+router.history.current.params.siteId+"/user/reorder/last").then(response=>{
            if (response && !response.error)  commit('setLastOrder',response.data)
        });

    },

    async shareBasket({commit},toCardNunber){

        await axios.post("/site/"+router.history.current.params.siteId+"/user/basket/share",{toShortCardNumber:toCardNunber,basket:Favorites.cleanseMany(this.getters.order.items)}).then(response=>{
            if (response && !response.error)  console.log(commit,response);
        });

    },

    async getSharedBaskets({commit}){

        if(this.getters.isAuthed!==true || router.history.current.params.siteId=='0') return;

        await axios.get("/site/"+router.history.current.params.siteId+"/user/basket/share").then(response=>{
            if (response && !response.error)  commit('setSharedBasketItems',response.data);
        });

    },

    async removeFavoriteItem({commit},id){
        await axios.delete("/user/favorite/"+id).then(response=>{
            console.log(response,commit);
        });
    },

    async deleteSharedBasket({commit},id){
        await axios.delete("/user/basket/"+id).then(response=>{
            console.log(response,commit);
        });
    },

    async saveBillSplit({commit},data){

        await axios.put("/order/"+data.orderId+"/update",{bill_split:data}).then(response=>{
            if (response && !response.error) commit("setBillSplitSaved", response.data);
        });

    }

};

const mutations = {

    clearAddingItem(state){
        state.order.ading = null;
    },

    setOrderUuid(state,uuid){
        state.order.uuid = uuid;
    },

    setOrderId(state,id){
        state.order.id = id;
    },

    setTable(state, table) {
        state.table = table;
    },

    setPointsPayment(state, points_payment) {
        state.order.points_payment = points_payment;
    },

    setAddingItem(state, menuItem){
        Vue.set(state.order,'adding',Orders.setOrderItem(menuItem));
    },

    setEditingItem(state,uuid){
        let item = _.find(state.order.items,(item)=>{
            return item.uuid===uuid
        });
        if(!item) return;

        const cloned = JSON.parse(JSON.stringify(item));

        Vue.set(state.order,'adding',cloned);
    },

    setAddingItemQty(state, qty){

        Vue.set(state.order.adding, 'qty', qty);
        this.commit('checkBasketQtys');
    },

    initModModG(state,modG){
        if(state.order.adding.modifiers[modG.id]){ return; }
        Vue.set(state.order.adding.modifiers,modG.id,{
            id   : modG.id,
            modifierGroupName : modG.name,
            valid             : modG.modifiers.length<=modG.pivot.maximum,
            modifiers         : []
        });
    },
    removeModMod(state,modG){
        delete state.order.adding.modifiers[modG.id];
    },

    setWholeBasket(state,items){
        Vue.set(state.order,"items",items);
    },

    updateAddingModifier(state,modG){


        let that = this;
        let mods   = _.clone(state.order.adding.modifiers[modG.id].modifiers);
        _.each(modG.modifiers,(mod)=>{
            mods.push(Orders.cleanseModifier(mod));

            if(mods.length>modG.pivot.maximum){// && modG.isSub!==true //todo i had to remove this to make it work... really not sure why.  look out for oddities that remove modiifiers when they shouldnt
                that.commit("removeModifierByParent", {
                    "parentModgId"  : modG.id,
                    "parentModId"   : modG.modifiers[0].id
                });
                mods.shift();
            }

        });

        //console.log('addee mod',mods);

        Vue.set(state.order.adding.modifiers[modG.id],"modifiers",mods);

        this.commit('updateModGValidStatus',{modG:modG,mods:mods});
    },

    updateAddingModifierQty(state,data){


        let mods   = _.clone(state.order.adding.modifiers[data.modG.id].modifiers);
        _.each(mods,(mod)=>{
            if(mod.pos_id===data.pos_id) mod.qty = data.qty;
        });
        Vue.set(state.order.adding.modifiers[data.modG.id],"modifiers",mods);

        this.commit('updateModGValidStatus',{modG:data.modG,mods:mods});

        this.commit('checkBasketQtys');

    },

    updateModGValidStatus(state,data){

        let isValid;
        if(data.modG.display_type!=='QuantityX' && data.modG.display_type!=='FreeText'){
            if(data.modG.isSub===true){
                isValid = (data.mods.length>=data.modG.pivot.minimum);
            }else{
                isValid = (data.mods.length>=data.modG.pivot.minimum && data.mods.length<=data.modG.pivot.maximum);
            }
        }else{
            let totalQty = 0;
            _.each(data.mods, function (mod) {
                return totalQty += mod.qty;
            });
            isValid = (totalQty>=data.modG.pivot.minimum && totalQty<=data.modG.pivot.maximum);
        }

        Vue.set(state.order.adding.modifiers[data.modG.id],"valid",isValid);

    },

    removeModifier(state,modG){

        let mods = _.clone(state.order.adding.modifiers[modG.id].modifiers);
            mods = _.without(mods, _.findWhere(mods, {
                pos_id: modG.modifiers[0].pos_id
            }));
        Vue.set(state.order.adding.modifiers[modG.id],"modifiers",mods);

        let isValid = (mods.length>=modG.pivot.minimum && mods.length<=modG.pivot.maximum);
        Vue.set(state.order.adding.modifiers[modG.id],"valid",isValid);

        this.commit("removeModifierByParent", {
            "parentModgId"  : modG.id,
            "parentModId"   : modG.modifiers[0].id
        });
        this.commit('checkBasketQtys');
    },

    removeModifierById(state,data){

        let mods   = _.clone(state.order.adding.modifiers[data.modGId].modifiers);
        mods = _.without(mods, _.findWhere(mods, {
            id: data.modId
        }));
        Vue.set(state.order.adding.modifiers[data.modGId],"modifiers",mods);

        this.commit('removeModifierByParent',{
            parentModgId : data.modGId,
            parentModId  : data.modId,
        });

        this.commit('checkBasketQtys');


        //console.log(state.order.adding);

    },

    removeModifierByParent(state,parentData){

        let mods          = _.clone(state.order.adding.modifiers);
        let deleteModGIds = [];

        _.each(mods,(modG,index)=>{
            _.each(modG.modifiers,(mod,ind)=>{
                if(mod.parent && mod.parent.parentModgId===parentData.parentModgId && mod.parent.parentModId===parentData.parentModId) deleteModGIds.push([index,ind]);
            });
        });

        _.each(deleteModGIds,(value)=>{
            mods[value[0]].modifiers.splice(value[1], 1);
            if(mods[value[0]].modifiers.length===0) delete mods[value[0]];
        });

        Vue.set(state.order.adding,"modifiers",mods);

        this.commit('checkBasketQtys');

    },

    deleteAddingItem(state){

        Vue.set(state.order,"adding",null);
    },

    commitAddingItem(state) {

        const items     = JSON.parse(JSON.stringify(state.order.items));
        const cloned    = JSON.parse(JSON.stringify(state.order.adding));
        if(!cloned) return;
        if(cloned.modifier_group) delete cloned.modifier_group;
        if(cloned.recipe) delete cloned.recipe;

        items.push(cloned)

        Vue.set(state.order,"items",items);

        this.commit("deleteAddingItem");

    },

    setAddingChange(state,value){
        Vue.set(state.order,"addingChanged",value);
    },

    cancelAddingChange(state){
        Vue.set(state.order,"addingChanged",false);
    },


    commitFavoriteItem(state,item) {
        state.order.items.push(item)
    },

    commitEditingItem(state){

        let index = _.findIndex(state.order.items,(item)=>{
            return item.uuid === state.order.adding.uuid;
        });

        const cloned = JSON.parse(JSON.stringify(state.order.adding));
        delete cloned.modifier_group;
        delete cloned.recipe;

        Vue.set(state.order.items,index,cloned);
        this.commit("deleteAddingItem");
        this.commit('checkBasketQtys');

    },

    updateAdding(state,data){
        Vue.set(state.order.adding,data.key,data.value);
    },

    removeBasketItem(state, uuid){
        state.order.items = _.without(state.order.items, _.findWhere(state.order.items, {
            uuid: uuid
        }));
        this.commit('checkBasketQtys');
    },

    setCoursingToOrderItems(state,items){
        state.order.items = Orders.setCoursingToOrderItems(items);
    },

    setLastOrder(state,lastOrder){

        if(lastOrder.length===0){
            window.CP.$confirm({
                title: 'Same Again?',
                message: 'You have no previous orders at this pub.',
                button: {
                    yes: 'OK'
                },
                callback: confirm => {
                    if(confirm) CDV.tap('medium');
                }
            });

            return;
        }
        CDV.notify('success');

        let orderItems = state.order.items
        _.each(lastOrder,(item)=>{
            orderItems.push(item);
        })
        Vue.set(state.order,"items",orderItems);
        this.commit('setNavOpen',false);
        router.push('/site/'+router.history.current.params.siteId+'/basket').catch(()=>{});
    },



    clearOrder(state){
        let data = {
            uuid    : null,
            id      : null,
            points_payment : null,
            adding  : null,
            addingChanged:false,
            basketHasOOS:false,
            items   : [],
        }
        Vue.set(state.order,"items",[]);
        Vue.set(state,"order",data);
    },

    newBillSplit(state){
        state.billSplit = {};
    },

    updateBillSplit(state,billSplit){
        state.billSplit = billSplit;
    },

    setBillSplitItem(state,value){
        if(!state.billSplit[value.personIndex]) Vue.set(state.billSplit,value.personIndex,{});
        let price = (!state.billSplit[value.personIndex][value.selectedIndex])?value.total:null;
        Vue.set(state.billSplit[value.personIndex], value.selectedIndex, price);
    },

    setBillSplitSaved(state){
        return state.billSplit
    },

    importSharedBaskets(state){

        let basketIds = [];
        _.each(this.getters.sharedBasketItems.basket,(item)=>{
            state.order.items.push(item.item);
            basketIds.push(item.id);
        });
        _.each(basketIds,(id)=> {
            this.dispatch('deleteSharedBasket',id);
        });
        this.commit('setSharedBasketItems',[]);

    },

    checkBasketQtys(state){

        let isError = false;
        const allQtys = JSON.parse(JSON.stringify(this.getters.qtys));

        let basket = [];
        _.each(this.getters.order.items,(item)=>{

            item.stockLevelError = (item.qty>allQtys[item.pos_id]);
            if(item.stockLevelError===true) isError = true;
            item.availableStock = allQtys[item.pos_id];
            allQtys[item.pos_id] = (allQtys[item.pos_id]-item.qty);
            if(allQtys[item.pos_id]<0) allQtys[item.pos_id] = 0;

            _.each(item.modifiers,(modG)=>{
                _.each(modG.modifiers,(mod)=>{

                    mod.stockLevelError     = ((mod.qty*item.qty)>allQtys[mod.pos_id]);
                    mod.availableStock      = allQtys[mod.pos_id];
                    allQtys[mod.pos_id]  = (allQtys[mod.pos_id]-(mod.qty*item.qty));
                    if(allQtys[mod.pos_id]<0) allQtys[mod.pos_id] = 0;
                    if(mod.stockLevelError===true) isError = true;
                });
            });

            basket.push(item);

        });

        Vue.set(state.order,'items', basket);
        Vue.set(state.order,'basketHasOOS', isError);

    },


};

export default {
    state,
    getters,
    actions,
    mutations,
};