<template>
  <div class="o-placeholder">
    <div v-html="site.icon" class="h-color--fill"></div>
    <h1 v-if="!isAtc" :class="(large)?'f-cta1':'f-cta3'">Image<br />Coming<br />Soon</h1>
  </div>
</template>

<script>
export default {
  name: 'Placeholder',
  props:['isAtc','large'],
  computed: {
    site(){
      return this.$store.getters.site
    }
  }
}

</script>
