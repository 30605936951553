import Config from "@/config";
import store from "@/store"

export class Sites {

    /**
     *
     * @returns {*}
     */
    static current(){
        let site = store.getters.site;
        if(!site){ return; }
        site.tableNumber = store.getters.table;
        //site.bg = this.bg(site);
        return site;
    }

    /**
     *
     * @param site
     * @returns {string}
     */
    static bg(site){

        let base = Config.getImageBase();
        let bg   = site.site_background;
        if(!bg){ return ''; }
        let urls = "url("+base+bg.x1+") 1x, url("+base+bg.x2+") 2x, url("+base+bg.x3+") 3x";

        return  "background-image:-webkit-image-set("+urls+")";

    }

    /**
     *
     * @param lat1
     * @param lon1
     * @param lat2
     * @param lon2
     * @param unit
     * @returns {number}
     */
    static getDistance(lat1, lon1, lat2, lon2, unit) {
        var radlat1 = Math.PI * lat1/180
        var radlat2 = Math.PI * lat2/180
        var theta = lon1-lon2
        var radtheta = Math.PI * theta/180
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist)
        dist = dist * 180/Math.PI
        dist = dist * 60 * 1.1515
        if (unit=="K") { dist = dist * 1.609344 }
        if (unit=="N") { dist = dist * 0.8684 }
        return dist
    }


}

