import _ from "underscore";

const state = {
  transitionName:'fade',
  customer:null,

  booking:{
      party_size : null,
      area       : null,
      time       : null,
      date       : null,
      comments   : null,
      uuid       : null,
      promotion  : null,
      isEdit     : false,
      isPreOrder : false,
      reference  : null,
      isConfirmed:false,
      holding_resdiary_reference:null,
      holding_time:null
  },
  originalBooking:null,
  packages : {
    totals     : {},
    items      : [],
  },
  site_uuid : null,
  site      : null,
  sites     : [],
  bookingSuccess   : null,
  stripePK:null,
  setups:{}
};

const getters = {
    //transitionName    :(state)=>state.transitionName,
    //customer          : (state)=>state.customer,
    booking           : (state)=>state.booking,
    originalBooking   : (state)=>state.originalBooking,
    setups            : (state)=>state.setups,
    setup             : (state,getters)=>(site_uuid)=>{
        return getters.setups[site_uuid];
    },
    bookingIsChanged : (state,getters)=>()=>{
        return _.isEqual(getters.booking,getters.originalBooking);
    },
    bookingSuccess    : (state)=>state.bookingSuccess,
    stripePK          : (state)=>state.stripePK,
    hasBookingInfo    : (state,getters)=>()=>{
        return (!(getters.booking.party_size===null || getters.booking.area===null || getters.booking.time===null || getters.booking.date===null));
    },
    hasBookingSuccess : (state,getters)=>()=>{
        return (getters.bookingSuccess);
    },
    site_uuid  : (state)=>state.site_uuid,
    /*site       : (state,getters)=>()=>{

        return  _.find(getters.sites,(site)=>{
            return (site.uuid===getters.site_uuid);
        });

    },*/
    sites   : (state)=>state.sites,
};

const actions = {

};

const mutations = {

    setSite(state,uuid){
        state.site_uuid = uuid;
    },
    setSites(state,sites){
        state.sites = sites;
    },
    setBooking(state,keyvalue){
        state.booking[keyvalue.key] = keyvalue.value;
    },
    setOriginalBooking(state,booking){
        state.originalBooking = JSON.parse(JSON.stringify(booking));
    },
    setBookingSuccess(state,success){
        state.bookingSuccess = success;
    },
    setPackages(state,keyvalue){
        state.packages[keyvalue.key] = keyvalue.value
    },
    addPackage(state,packageItem){
        state.packages.items.push(packageItem)
    },
    setTransitionName(state,name){
        state.transitionName = name;
    },
    setStripePK(state,pk){
        state.stripePK = pk;
    },
    setSetup(state,data){
        state.setups[data.site_uuid]=data.setup;
    },
    resetDiary(){
        //this.$store.commit('clearOrder');
      /*  state.guest = {
            first_name  : null,
            last_name   : null,
            telephone   : null,
            email       : null,
            comments    : null
        }*/
        state.booking={
            party_size : null,
            area       : null,
            time       : null,
            date       : null,
            comments   : null,
            uuid       : null,
            promotion  : null,
            isEdit     : false,
            isPreOrder : false,
            reference  : null,
            isConfirmed:false,
            holding_resdiary_reference:null,
            holding_time:null
        }
        state.originalBooking=null;
        state.packages = {
            totals     : {},
            items      : [],
        }
        state.stripePK = null;
    }

};

export default {
    state,
    getters,
    actions,
    mutations,
};

