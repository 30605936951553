
import Vue from "vue";

import store from "./store";
import config from "./config";
import axios from "axios";
import VueAxios from "vue-axios";
import VueSocialauth from "vue-social-auth";
import router from "./router";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";

import * as VeeValidate from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import { required } from "vee-validate/dist/rules";

import * as Sentry from "@sentry/vue";

window.isNavOpen = false;
window.isSafari = !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/);
window.iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
window.isSafariiOs = (window.isSafari && window.iOS);

//console.log(window.isSafari,window.iOS,window.isSafariiOs);

import VCalendar from 'v-calendar';
Vue.use(VCalendar,{});

import VueConfirmDialog from '@/components/App/popup/index.js';
Vue.use(VueConfirmDialog)

import { ObserveVisibility } from 'vue-observe-visibility'
Vue.directive('observe-visibility', ObserveVisibility)

import VueCountdownTimer from 'vuejs-countdown-timer';
Vue.use(VueCountdownTimer);


//social
window.authOptions = {
  providers: {
    facebook: {
      clientId: "249238670609188",
      redirectUri: (window.cordova)?process.env.VUE_APP_API_DOMAIN+"/auth/facebook/redirect":'https://'+window.location.hostname+"/auth/facebook/redirect",
    },
    google: {
      clientId: "518748135544-giu1m2nktb4mfaapbhvtiilbt1otg36b.apps.googleusercontent.com",
      redirectUri: (window.cordova)?process.env.VUE_APP_API_DOMAIN+"/auth/google/redirect":'https://'+window.location.hostname+"/auth/google/redirect",
    },
    apple: {
      clientId: "uk.co.chilledpubs.sso",
      redirectUri: (window.cordova)?process.env.VUE_APP_API_DOMAIN+"/auth/apple/redirect":'https://'+window.location.hostname+"/auth/apple/redirect",
    },
  },
}

Vue.use(VueAxios, axios);
Vue.use(VueSocialauth, window.authOptions);

import VueEasyLightbox from 'vue-easy-lightbox'
Vue.use(VueEasyLightbox)


// SMOOTH SCROLL
import smoothscroll from "smoothscroll-polyfill";
!smoothscroll.polyfill();

import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
  load: {
    key: (window.cordova)?process.env.VUE_APP_GOOGLE_MAPS_API_KEY_CORDOVA:process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
  },
});

//Gallery
import VuePictureSwipe from "vue-picture-swipe";
Vue.component("vue-picture-swipe", VuePictureSwipe);

//VUE Router
import VueRouter from "vue-router";
Vue.use(VueRouter);

// TOUCH EVENTS
import Vue2TouchEvents from "vue2-touch-events";
Vue.use(Vue2TouchEvents);

// VUE CLICKABLE
import VueClosable from "vue-closable";
Vue.use(VueClosable);

// CONFETTI
import VueConfetti from "vue-confetti";
Vue.use(VueConfetti);

import {
  Swiper as SwiperClass,
  Pagination,
  Mousewheel,
  Autoplay,
  EffectCube,
} from "swiper/js/swiper.esm";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";

// Swiper modules
SwiperClass.use([Pagination, Mousewheel, Autoplay, EffectCube]);
// import style (<= Swiper 5.x)
import "swiper/css/swiper.css";

Vue.use(getAwesomeSwiper(SwiperClass));

// vue-inview
import inViewportDirective from "vue-in-viewport-directive";
inViewportDirective.defaults.margin = "-15% 0%";
Vue.directive("in-viewport", inViewportDirective);

// FRIENFDLY IFRAME
import { VueFriendlyIframe } from "vue-friendly-iframe";
Vue.use(VueFriendlyIframe);

// PINCH ZOOM
import PinchZoom from "vue-pinch-zoom";
Vue.component("pinch-zoom", PinchZoom);

// UUID
import UUID from "vue-uuid";
Vue.use(UUID);

// LazyLoad Images
import VueLazyload from "vue-lazyload";
Vue.use(VueLazyload, {
  silent: false,
});

// moment
import moment from "moment";
import "./registerServiceWorker";
Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(value).format("DD/MM/YYYY HH:mm");
  }
});

// Progressbasr
import VueProgressBar from "vue-progressbar";
Vue.use(VueProgressBar, {
  color: "rgb(202,136,39)",
  failedColor: "red",
  height: "20px",
});

// GOOGLEMAPS ////////
Vue.config.productionTip = false;

// FILTERS ////
Vue.filter("truncate", function(value, limit) {
  if (!value) {
    return value;
  }
  if (value.length > limit) {
    value = value.substring(0, limit - 3) + "...";
  }
  return value;
});

// VALIDATION /////////////////////////////
// install rules and localization
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});
extend("required", {
  ...required,
  message: "The {_field_} is required.",
});

extend("required2", {
  ...required,
  message: "This field is required.",
});

extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Passwords do not match",
});
extend("confirmEmail", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Emails do not match",
});
extend("confirmAmount", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Amounts do not match",
});
extend("cardnumber", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Card Numbers do not match",
});
extend("is18", {
  validate(value) {
    return moment(value).isBefore(moment().subtract(18, "years"));
  },
  message: "You must be 18 to use this app.",
});
extend("within30Days", {
  validate(value) {
    return moment(value).isAfter(moment().subtract(30, "days")) && moment(value).isBefore(moment());
  },
  message: "You can only redeem points on orders placed in the last 30 days.",
});

extend("max_length", {
  params: ["len"],
  validate(value, { len }) {
    return (parseInt(value.length)<parseInt(len))
  },
  message: "Maximum limit reached.",
});

extend("dateInPast", {
  validate(value) {
    return (moment(value).isBefore(moment().add(1,'day'),'day'))
  },
  message: "Date cannot be in the future.",
});



extend("currency", {
  validate(value) {
    var regex  =  /^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/;
    var numStr = value;
    return (regex.test(numStr))
  },
  message: "Invalid amount.",
});

extend("isTrue", {
  params: ["target"],
  validate(value) {
    return value === true;
  },
  message: "You must agree to the Terms & Conditions to continue.",
});

extend("decimal", {
  validate: (value, { decimals = "*", separator = "." } = {}) => {
    if (value === null || value === undefined || value === "") {
      return {
        valid: false,
      };
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value),
      };
    }
    const regexPart = decimals === "*" ? "+" : `{1,${decimals}}`;
    const regex = new RegExp(
      `^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`
    );

    return {
      valid: regex.test(value),
    };
  },
  message: "The {_field_} field must contain only decimal values",
});
extend("min_value", {
  params: ["len"],
  validate(value, { len }) {
    return (parseFloat(value)>parseFloat(len))
  },
  message: "Amount must be greater than £4.99",
});

extend("min_value29", {
  params: ["len"],
  validate(value, { len }) {
    return (parseFloat(value)>parseFloat(len))
  },
  message: "Amount must be greater than £0.29",
});

extend("url", {
  validate(string) {

    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";

  },
  message: "Please enter a valid url (including http:// or https://)",
});


// Install components globally
Vue.use(VeeValidate, { inject: false });
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
///////////////////////////////

import VueKeepScrollPosition from "vue-keep-scroll-position";
Vue.use(VueKeepScrollPosition);

// AXIOS  ///////////////////////////
axios.defaults.baseURL = config.getApiBase();
axios.defaults.headers.common["Accept"]       = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["X-P2F"]   = process.env.VUE_APP_API_TOKEN;

axios.interceptors.request.use(function(config) {
  if (store.getters.isAuthed) config.headers.common["Authorization"] = `Bearer ` + store.getters.access_token;
  return config;
});
axios.interceptors.response.use(undefined, function(error) {


  if (error) {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      store.dispatch("logOut");
      router.push("/intro");
    } else if (error.response && error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;

      window.CP.$confirm({
        title  :'Account Blocked',
        message: "Your account has been made inactive, Please contact a member of staff.",
        button : {
          yes : 'OK'
        },
        callback: confirm => {
          if (confirm){
            CDV.tap('medium');
            store.dispatch("logOut");
            router.push("/intro");
          }
        }
      });

    } else if (error.response && error.response.status === 412 && !originalRequest._retry) {
      originalRequest._retry = true;

      window.CP.$root.$emit('toggleFooter', false);
      let isOrder = '';
      if(router.history.current.params.order) isOrder = '/'+router.history.current.params.order;
      router.push("/site/" + router.history.current.params.siteId + "/account/tncs" + isOrder).catch(() => {});
    } else if (error.response && error.response.status === 499 && !originalRequest._retry) {
        originalRequest._retry = true;
        //if(response.data.online===true){
       //
        //}else{
          document.getElementById('app').style = {opacity:1};
          router.replace("/site/"+(( router.history.current.params.siteId)? router.history.current.params.siteId:0)+"/maintenance").catch(() => {});
          store.commit("setNavOpen",false);
          window.CP.$root.$emit('toggleFooter',false);
        //}
    } else {
      return Promise.reject({ error: true, data: error });
    }
  }
});

import App from "./App.vue";
import CDV from "@/Libary/Cordova/CDV";

Sentry.init({
  Vue,
  dsn: "https://86fc9f44aeb01f2f1b19ad8af85db563@o4507379582763008.ingest.de.sentry.io/4507379586105424",
  integrations: [
    Sentry.replayIntegration(),
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// END AXIOS /////////////////////////////
let init = (isCordova)=>{
  window.CP = new Vue({
    store,
    router,
    data(){
      return {
        booted:false
      }
    },
    mounted(){

      const urlParams = new URLSearchParams(window.location.search);
      this.$store.commit('setLinkedPageParams',urlParams);

      if(isCordova===false && window.location.pathname!=='/') window.linkedPage = window.location.pathname;

      if (this.$route.name!=="Boot") this.$router.replace("/").catch(()=>{});
    },
    render: h => h(App)
  }).$mount('#app')
};

window.linkedPage = null;

if(window.cordova){

  let element = document.getElementById("body");
      element.classList.add("cordova");

      window.isCordova = true;

      document.addEventListener('deviceready', () => {

        window.open = window.cordova.InAppBrowser.open;
        window.StatusBar.styleDefault();

        window.universalLinks.subscribe('deepLink', function (eventData) {
          if(eventData.path!=='/') window.linkedPage = eventData.path;
          if(router.history.current.name!=="Boot") router.replace("/").catch(()=>{});
        });

        window.cordova.getAppVersion.getVersionNumber().then(function (version) {
          store.commit('setAppVersion',version);
        });

        init(true);
      }, false)



}else{
  init(false);
}



