import Vuex from "vuex";
import Vue from "vue";
import auth from "./modules/auth";
import order from "./modules/order";
import data from "./modules/data";
import user from "./modules/user";
import config from "./modules/config";
import customer from "./modules/customer";
import booking from "./modules/booking";
import guest from "./modules/guest";
import createPersistedState from "vuex-persistedstate";

// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
    modules: {
        auth,
        order,
        user,
        data,
        config,
        customer,
        guest,
        booking
    },
    plugins: [createPersistedState({
        paths:["auth","config","user","order","customer",'guest']
    })],
});
