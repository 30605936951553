export default class CDV {

    static tap(style){
        if (window.TapticEngine) window.TapticEngine.impact({style:style });
    }

    static notify(type){
        if(window.TapticEngine) window.TapticEngine.notification({type: type});
    }

    static logEvent(event,params){
        if(window.cordova && window.cordova.plugins && window.cordova.plugins.firebase && window.cordova.plugins.firebase.analytics) window.cordova.plugins.firebase.analytics.logEvent('P2F: '+event,params)
    }
    
    static setCurrentScreen(name){
        if(window.cordova && window.cordova.plugins && window.cordova.plugins.firebase && window.cordova.plugins.firebase.analytics) window.cordova.plugins.firebase.analytics.setCurrentScreen('P2F: '+name);
    }

}