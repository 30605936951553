import axios from "axios";
import router from "@/router";
import Vue from "vue";
import config from "@/config";
import _ from "underscore";

const state = {
    version: (localStorage.getItem('vuex') && JSON.parse(localStorage.getItem('vuex')).user)?JSON.parse(localStorage.getItem('vuex')).user.version:null,
    guest: (localStorage.getItem('vuex') && JSON.parse(localStorage.getItem('vuex')).user)?JSON.parse(localStorage.getItem('vuex')).user.guest:null,
    user: (localStorage.getItem('vuex') && JSON.parse(localStorage.getItem('vuex')).user)?JSON.parse(localStorage.getItem('vuex')).user.user:null,
    site: (localStorage.getItem('vuex') && JSON.parse(localStorage.getItem('vuex')).user)?JSON.parse(localStorage.getItem('vuex')).user.site:null
};

const getters = {
    version      : (state) => (state.version)?state.version:'3.0.0',
    site         : (state) => state.site,
    user         : (state) => state.user,
    guest        : (state) => state.guest,
    page         : (state) => state.page,
    pushToken    : (state)=> (state.pushToken)?state.pushToken:null,
    //linkedPage   : (state) => state.linkedPage,
    linkedPageParams         : (state) => (state.linkedPageParams)?state.linkedPageParams:null,
    isActive                 : (state) => (state.user)?state.user.is_active:true,
    isTeamMember             : (state) => (state.user)?state.user.is_team_member:false,
    isManagement             : (state) => (state.user)?state.user.is_management:false,
    showLab                  : (state) => (state.user)?state.user.should_see_lab:false,


    isHubTeam                : (state,getters)=>()=>{

        if(getters.isAuthed===false) return false;
        if(getters.isTeamMember===false) return false;

        let found = _.find(state.user.team_member_sites,(v)=>{
            return (v.trim()==='The Hub');
        });

        return (found);

    },

    tncAgreed                : (state) => (state.user)?state.user.tncAgreed:true,
    tncVersion               : (state) => (state.user)?state.user.tnc_version:999999,
    seenFavoriteActions      : (state) => (state.seenFavoriteActions)?state.seenFavoriteActions:0,
    seenBasketActions        : (state) => (state.seenBasketActions)?state.seenBasketActions:0,
    seenBookingBasketActions : (state) => (state.seenBookingBasketActions)?state.seenBookingBasketActions:0,
};

const actions = {

    socialSetUser({commit}, user) {
        commit("setUser", user);
    },

    setSite({commit},site){
        commit("clearOrder");
        commit("setSite", site);
    },

    setTable({commit},table){
        commit("setTable", table);
    },

   // setLinkedPage({commit},linkedPage){
     //   commit("setLinkedPage", linkedPage);
    //},

    async getUser({commit}){
        if(this.getters.isAuthed===false) return;
        await axios.get('/guest').then((response)=>{
            if (response && !response.error){
                commit("setUser", response.data);
            }
        });
    },

    async putUser({commit},form){
        await axios.put('/guest',form).then((response)=>{
            if (response && !response.error) commit("setUser", response.data);
        });
    },

    async getSite({commit}){

        if(!router.history.current.params.siteId) return;
        if(router.history.current.params.siteId=="0" || router.history.current.params.siteId=="undefined") return;
        await axios.get('/site/'+router.history.current.params.siteId).then((response)=>{

            this.dispatch("request",{ verb:'get', key:'menus', url: config.endpoint('menus',{siteId:router.history.current.params.siteId,'type':'food'}) });
            this.dispatch("request",{ verb:'get', key:'menus', url: config.endpoint('menus',{siteId:router.history.current.params.siteId,'type':'drinks'}) });

            if (response && !response.error) commit("setSite", response.data); //todo - detect empty response - send to select-site
        });
    },

    async putUserLogin({commit},form) {
        await axios.put('/guest/login', form).then((response) => {
            if (response && !response.error) commit("setUser", response.data);
        });
    },

    async transferPoints({commit},form) {

        await axios.put('/site/'+router.history.current.params.siteId+'/loyalty/balance/transfer', form).then((response) => {

            window.CP.$confirm({
                title: 'Transfer Points',
                message: response.data.message,
                button: {
                    yes: 'OK'
                },
                callback:success=>{
                    if(success && !response.error) router.push('/site/'+router.history.current.params.siteId+'/account')
                }
            });
            commit("setTransfer", response.data);

        });

    },



    async setPage({commit},page){
        if(page!=="/")  commit("setPage", page);
    },

    async agreeToTnC({commit},data){
        await axios.put('/tnc/agree', {tnc_version:data.version}).then((response) => {
            if (response && !response.error){
                commit("setUser", response.data);
            }
        });
    },

    clearUser({commit}){
        commit('clearUser');
    }

};

const mutations = {

    setRedeem(){},

    setSite(state, site) {
        Vue.set(state,"site",site);
    },

    setGuest(state, guest) {
        state.guest = guest;
    },

    //setLinkedPage(state, linkedPage) {
      //  state.linkedPage = linkedPage;
    //},
    setLinkedPageParams(state, linkedPageParam) {
        state.linkedPageParams = linkedPageParam;
    },

    setUser(state, user) {

        state.user = user;
        if(router.history.current.meta.isDiary===true){
            //router.replace('/site/'+router.history.current.params.siteId+'/summary').catch(()=>{});
        }else if(router.history.current.params.order==='order'){
            router.replace('/site/'+router.history.current.params.siteId+'/summary').catch(()=>{});
        }else if(router.history.current.params.order && router.history.current.params.order.includes('giftcard')){
            router.replace('/site/'+router.history.current.params.siteId+'/'+router.history.current.params.order.replace('::','/')).catch(()=>{});
        }else if(router.history.current.params.order && router.history.current.params.order.includes('check')){
            router.replace('/site/'+router.history.current.params.siteId+'/'+router.history.current.params.order.replace('::','/')+'/summary').catch(()=>{});
        }else if(window.linkedPage){
            router.replace(window.linkedPage).catch(()=>{});
            window.linkedPage = null;
        }else if(this.getters.page && !this.getters.page.includes('/login') && !this.getters.page.includes('/tncs')){

           router.replace(this.getters.page).catch(()=>{});
        }else if(router.history.current.params.siteId && router.history.current.params.siteId!=="0" && router.history.current.params.siteId!=="undefined"){

            router.replace('/site/'+router.history.current.params.siteId+'/home').catch(()=>{});
        }else{

            router.replace('/select-site').catch(()=>{});
        }

    },

    setTransfer(state,response){
        return response
    },

    clearUser(state){
        state.user = null;
        state.page = '';
        state.order = {};
    },

    setPage(state, page) {
        state.page = page;
    },

    setSeenFavoriteActions(state) {
        state.seenFavoriteActions = state.seenFavoriteActions+1;
    },

    setSeenBasketActions(state) {
        state.seenBasketActions = state.seenBasketActions+1;
    },
    setSeenBookingBasketActions(state) {
        state.seenBookingBasketActions = state.seenBookingBasketActions+1;
    },

    setAppVersion(state,version) {
        state.version = version;
    },

    setPushToken(state,pushToken) {
        state.pushToken = pushToken;
        this.dispatch("registerPushToken");
    }

};

export default {
    state,
    getters,
    actions,
    mutations,
};