<template>
  <div id="app" class="app " style="opacity:0" :data-platform="platform" :class='{
        "show-safe-padding"   : addSafePadding,
        "is-standalone"       : isStandalone,
        "extra-footer-padding": extraFooterPadding,
        "show-nav"            : isNavOpen,
        "is-cordova"          : isCordova,
        "has-waitTime"        : hasWaitTime,
        "is-loading"          : isLoading,
        "is-popstate"         : (popStateDetected)
      }'>


      <vue-progress-bar></vue-progress-bar>

      <div class="more-container" v-touch:swipe.right="swipeRight"><!--v-touch:swipe.left="swipeLeft"-->

          <transition :name="transitionName">
              <router-view :key="$route.fullPath" />
          </transition>
          <transition name="accordionTiny">
            <Nav v-if="$store.getters.site && showFooter"/>
          </transition>

      </div>

      <transition name="fade">
        <ModPopup v-if="$store.getters.subsPopupData" :data="$store.getters.subsPopupData" />
      </transition>

      <transition class="fade">
        <Img v-if="$store.getters.image" :id="$store.getters.image" />
      </transition>

      <NavMore v-if="!isPublicPage && $store.getters.site && !hideFooter"/>

      <a-t-c v-if="showATC" :pos="showATC" />

      <vue-confirm-dialog></vue-confirm-dialog>

  </div>

</template>


<script>

import Nav from "@/components/Layout/Nav";
import NavMore from "@/components/Layout/NavMore";
import axios from "axios";
import Img from "@/components/App/Img";
import ModPopup from "@/components/Menu/ModifierGroups/ModPopup";
import ATC from "@/components/App/ATC";
import _ from "underscore";
import {Sites} from "@/Libary/Sites/Sites";
import config from "@/config";
import CDV from "@/Libary/Cordova/CDV";
import {App} from "@/Libary/App/App";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken,onMessage } from "firebase/messaging";

export default {
  name: 'App',
  components:{
    ATC,
  //  Popup,
    NavMore,
    Nav,
    ModPopup,
    Img
  },
  data(){
    return {
      extraFooterPadding:false,
      showFooter:false,
      showATC:false,
      platform:null,
      isLoading:false,
      popStateDetected:false,
      to:null,
      to2:null,
      to3:null,
      noGeo:false
    }
  },
  beforeDestroy() {
    clearTimeout(this.to);
    clearTimeout(this.to2);
    clearTimeout(this.to3);
  },
  mounted(){

    window.isCordova        = (process.env.CORDOVA_PLATFORM!==undefined);
    window.doLastPageScroll = false;
    window.popStateDetected = false

    window.addEventListener('popstate', () => {
      this.popStateDetected = true;
      window.popStateDetected = true
      this.to = setTimeout(()=>{
        this.popStateDetected = false;
      },500);
    })
    this.init();
  },

  computed: {

    isCordova(){
      return (window.isCordova || process.env.CORDOVA_PLATFORM!==undefined);
    },
    isPublicPage() {
      return this.$route.meta.publicPage;
    },
    hideHeader() {
      return this.$route.meta.hideHeader;
    },
    hideFooter() {
      return this.$route.meta.hideFooter;
    },
    isNavOpen() {
      return this.$store.getters.isNavOpen;
    },
    transitionName(){
      return this.$store.getters.transitionName
    },
  /*  user () {
      return this.$store.getters.isActive;
    },*/
    addSafePadding(){
      return (window.isSafariiOs || this.isStandalone)
    },
    isStandalone(){
      return (window.matchMedia('(display-mode: standalone)').matches || window.isCordova)
    },
    site(){
      return this.$store.getters.site;
    },
    hasWaitTime(){
      return (this.$store.getters.waitTime());
    }
  },
  watch:{

    site(){
      this.appendThemeCss();
      this.appendCustomerFonts();
    },
   /* user () {
      this.watchUser();
    }*/
  },
  methods:{
  initPush(){

    let that = this;
    if((this.checkPlatform()==='iOS' || this.checkPlatform()==='Android') && window.cordova && window.cordova.plugins && window.cordova.plugins.firebase && window.cordova.plugins.firebase.messaging){
      window.cordova.plugins.firebase.messaging.requestPermission({forceShow: false}).then(function() {
        console.log("Push messaging is allowed");
        window.cordova.plugins.firebase.messaging.getToken().then(function(token) {
          console.log("Got device token: ", token);
          that.$store.commit('setPushToken',token)
        });
      });

      window.cordova.plugins.firebase.messaging.onTokenRefresh(function() {
        console.log("on token refresh");
        window.cordova.plugins.firebase.messaging.getToken().then(function(token) {
          console.log("Got device token: ", token);
          that.$store.commit('setPushToken',token)
        });
      });

      window.cordova.plugins.firebase.messaging.onBackgroundMessage(function(payload) {
        console.log("New background FCM message: ", JSON.stringify(payload));
        if(payload.link) that.$router.push(payload.link);
      });

      window.cordova.plugins.firebase.messaging.onMessage(function(payload) {
        console.log("New foreground FCM message: ", JSON.stringify(payload));
        that.$confirm({
          title   : (payload.aps && payload.aps.alert && payload.aps.alert.title)?payload.aps.alert.title:(payload.gcm && payload.gcm.title)?payload.gcm.title:'',
          message : (payload.aps && payload.aps.alert && payload.aps.alert.body)?payload.aps.alert.body:(payload.gcm && payload.gcm.body)?payload.gcm.body:'',
          button  : {
            yes: 'OK'
          },
          callback: confirm => {
            if (confirm){
              CDV.tap('medium');
              if(payload.link) that.$router.push(payload.link);
            }
          }
        });
      });

    }else {
      // TODO: Replace the following with your app's Firebase project configuration
      // See: https://firebase.google.com/docs/web/learn-more#config-object
      const firebaseConfig = {
        apiKey: "AIzaSyAgxNN3VvsVvxRFdPPCQmpiQ-p57G1Z2kg",
        authDomain: "cpv2-cordova.firebaseapp.com",
        projectId: "cpv2-cordova",
        storageBucket: "cpv2-cordova.appspot.com",
        messagingSenderId: "472101946846",
        appId: "1:472101946846:web:03f83d8cbf10d249e7c94f",
        measurementId: "G-EQ777D5EQK"
      };

      // Initialize Firebase
      const app = initializeApp(firebaseConfig);
      // Initialize Firebase Cloud Messaging and get a reference to the service
      const messaging = getMessaging(app);

      getToken(messaging, {vapidKey: process.env.FIREBASE_PUSH_KEY}).then((currentToken) => {
        if (currentToken) {
          console.log(currentToken);
          that.$store.commit('setPushToken', currentToken)
          // Send the token to your server and update the UI if necessary
          // ...
        } else {
          // Show permission request UI
          console.log('222No registration token available. Request permission to generate one.');
          // ...
        }
      }).catch(() => {

        that.$confirm({
          title: 'Notifications',
          message: 'We would like to send you notifications.',
          button: {
            yes: 'OK'
          },
          callback: confirm => {
            if (confirm) {
              Notification.requestPermission().then((permission) => {

                if (permission === 'granted') {
                  console.log('Notification permission granted.');

                  getToken(messaging, {vapidKey: process.env.FIREBASE_PUSH_KEY}).then((currentToken) => {
                    if (currentToken) {
                      console.log(currentToken);
                      that.$store.commit('setPushToken', currentToken)
                      // Send the token to your server and update the UI if necessary
                      // ...
                    } else {
                      // Show permission request UI
                      console.log('No registration token available. Request permission to generate one.');
                      // ...
                    }
                  }).catch((err) => {
                    console.log('An error occurred while retrieving token. ', err);
                    // ...
                  });

                }
              });
            }
          }
          // ...
        });

      });


      onMessage(messaging, (payload) => {
        console.log('Message received. ', payload);

        console.log(payload);

        that.$confirm({
          title: (payload.notification && payload.notification.title) ? payload.notification.title : '',
          message: (payload.notification.body) ? payload.notification.body.replace(/\./g, '<br /><br />') : '',
          button: {
            yes: 'OK'
          },
          callback: confirm => {
            if (confirm) {
              if (payload.data && payload.data.link) that.$router.push(payload.data.link);
            }
          }
        });

        // ...
      });

    }

  },
 /*   watchUser(){
      console.log(this.$store.getters.user,this.$store.getters.isActive,this.$route.meta.blockedPage)
      if(this.$store.getters.user!==null && this.$store.getters.isActive!==1 && this.$route.meta.blockedPage!==true){
        this.$router.replace("/site/"+this.$route.params.siteId+"/blocked");
      }
    },*/
    init(){

        this.setAnalyticsUserId();

        this.$root.$on('showATC',(value)=>{
          this.showATC=value;
        });
        this.$root.$on('toggleFooter',(value)=>{
          this.showFooter=value;
        });

        this.$root.$emit('toggleFooter',(this.$route.params.siteId!==undefined))

        this.$store.dispatch('getCustomer');
        this.$store.dispatch('getConfig');
        this.$store.dispatch('getSite');
        this.platform = this.checkPlatform();

      //  this.appendCustomerFonts();
      //  this.appendCustomerCss();
      //  this.getCustomer();
        setTimeout(this.checkIfInPub,1000);
        this.internetConnection();
        this.startQtyCheck();
        this.fixBodyHeight();
        //this.startAppVersionCheck();
        this.checkAppVersion();
        //this.handleOrientationChange();
        this.attachIFrameListener();
        this.confetti();
        this.initPush();
    },

    appendThemeCss(){8

      const old = document.getElementById("theme-css");
      if(old) old.remove()

      if(!this.site || !this.site.brand || !this.site.brand.theme || !this.site.brand.theme.css) return;

      let style = document.createElement('style');
          style.id = "theme-css";

          document.head.appendChild(style);

          style.appendChild(document.createTextNode(this.site.brand.theme.css));

    },

    appendCustomerFonts(){

      const old = document.getElementById("theme-fonts");
      if(old) old.remove()

      if(!this.site || !this.site.brand || !this.site.brand.theme || !this.site.brand.theme.google_font_url) return;

      let link = document.createElement('link');
          link.id = "theme-fonts";
          link.rel = "stylesheet";
          link.type = "text/css";
          link.media = "all";
          link.href = this.site.brand.theme.google_font_url;

      document.head.appendChild(link);

    },

    confetti(){

      if(this.$store.getters.moduleActive('app.menu.confetti')!==true)return;

      this.$store.dispatch('request', { verb:'get',url:config.endpoint('confetti'),key:config.endpoint('confetti') }).then(response=>{

        if(!response || (response && _.isEmpty(response.data))) return;

        let particles = [];
          _.each(response.data.fields,(v)=>{
              let particle = {
                type : 'image',
                url  : config.getImageBase()+v.attributes.image,
              };
              if(v.attributes.size!=='' && v.attributes.size!==null) particle.size = v.attributes.size;
              particles.push(particle);
          });
          this.$confetti.start({
            particles         : particles,
            particlesPerFrame : 0.1,
            defaultDropRate   : 7,
            defaultSize       : 10,
          });
          setTimeout(()=>{
            this.$confetti.stop();
          },7500);
      });

    },

    setAnalyticsUserId(){

      if(window.cordova && window.cordova.plugins.firebase && window.cordova.plugins.firebase.analytics){
        if(this.$store.getters.isAuthed && this.$store.getters.user) {
          window.cordova.plugins.firebase.analytics.setUserId(this.$store.getters.user.LoyaltyNumber+'');
        }else if(window.device && window.device.uuid){
          window.cordova.plugins.firebase.analytics.setUserId(window.device.uuid+'');
        }
        window.cordova.getAppVersion.getVersionNumber().then(function (version) {
          window.cordova.plugins.firebase.analytics.setUserProperty("app_version", version);
        });
      }


    },

    checkPlatform(){

      if(this.isCordova && process.env.CORDOVA_PLATFORM && process.env.CORDOVA_PLATFORM.toLowerCase()==='ios') return "iOS";

      if(this.isCordova && process.env.CORDOVA_PLATFORM && process.env.CORDOVA_PLATFORM.toLowerCase()==='android') return "Android";

      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/windows phone/i.test(userAgent)) return "Windows Phone";

      if (/android/i.test(userAgent)) return "Android";

      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return "iOS";

      return "unknown";

    },

    checkIfInPub(){

      if(this.noGeo===true) return;
      axios.get(config.endpoint('sites')).then((response)=>{

        if (!response || (response && response.error)) return;

          if(!("geolocation" in navigator)) {
            this.errorStr = 'Geolocation is not available.';
            this.locationError()
            return;
          }

          let inPub = null;
          let sites = response.data;
          navigator.geolocation.getCurrentPosition(pos => {

              _.each(sites,(site)=>{
                  let distance = Sites.getDistance(pos.coords.latitude,pos.coords.longitude,site.latitude,site.longitude,"K");
                  site.distance = distance;
                  if((distance*1000)<1000) inPub = JSON.parse(JSON.stringify(site)); //TODO this is set to 2km - if we get any pubs within this distance this needs addressing.
              });

              if(inPub===null || (inPub && this.$route.params.siteId && inPub.id==this.$route.params.siteId) || this.$route.meta.noGeo===true) return;

              window.isCordova = (process.env.CORDOVA_PLATFORM!==undefined);
              this.$store.commit('setSite', inPub);
              this.$store.commit("clearOrder");
              this.$store.commit("clearAllCachedData");

              this.$router.replace('/site/'+inPub.id+'/pre-home/');
              CDV.tap('medium');

              /*this.$confirm({
                title: '*IMPORTANT*',
                message: "We have detected your are in "+inPub.name+'. We will select this pub for you.',
                button: {
                  yes: 'OK'
                },
                callback: () => {
                    this.$store.commit('setSite', inPub);
                    this.$store.commit("clearOrder");
                    this.$store.commit("clearAllCachedData");

                    this.$router.replace('/site/'+inPub.id+'/pre-home/');
                    CDV.tap('medium');
                }
              });*/

          },()=>{
            this.noGeo = true;
            if(!this.$route.params.siteId) this.locationError();
          },{ timeout: 5000, enableHighAccuracy: true });

      });

    },

    locationError(){

      if(this.isCordova && process.env.CORDOVA_PLATFORM && process.env.CORDOVA_PLATFORM.toLowerCase()==='ios') {

        CDV.notify('warning');
        this.$confirm({
          title: '*IMPORTANT*',
          message: "We cannot check your location, please ensure you have selected the correct "+this.$store.getters.site.brand.term_singular_site_name+".",
          button: {
            yes: 'OK'
          },
          callback: confirm => {
            if (confirm) CDV.tap('medium');
          }
        });

      }
    },

    internetConnection(){

      const that = this;

      document.addEventListener("resume", ()=>{

        that.checkAppVersion();

        window.isCordova = (process.env.CORDOVA_PLATFORM!==undefined);

        if(window.cordova){
          window.cordova.getAppVersion.getVersionNumber().then(function (version) {
            that.$store.commit('setAppVersion',version);
          });
        }

        const video = document.getElementsByTagName("video")[0];

        if(video) video.play();
        this.$store.dispatch('getConfig');
        this.$store.dispatch('getSite');
        this.to2 = setTimeout(()=>{
          this.checkIfInPub();
        },250);

      }, false);

      document.addEventListener("offline", ()=>{
        this.$router.replace("/site/"+this.$route.params.siteId+"/offline");
        this.$store.commit("setNavOpen",false);
        this.$root.$emit('toggleFooter',false);
      }, false);

      document.addEventListener("online", ()=>{
        if(this.$route.name==="Offline") this.$router.replace("/select-site");
        this.$store.dispatch('getConfig');
        this.$store.dispatch('getSite');
      }, false);

    },

    attachIFrameListener(){

      /*const that = this;
      window.addEventListener("message", (event) => {
        //console.log(event);
        if (!event.origin.includes("phone2fork")) return;
        if(that.$route.name==='Payment')  that.$router.replace("/site/"+that.$route.params.siteId+"/success/"+that.$route.params.uuid);
      }, false);*/

    },

    fixBodyHeight(){
      setTimeout(()=> {
        let vh = window.innerHeight * 0.01;
        document.body.style.setProperty('--vh', `${vh}px`);
      },300);
      window.addEventListener('resize', () => {
        setTimeout(()=>{
          let newvh = window.innerHeight * 0.01;
          document.body.style.setProperty('--vh', `${newvh}px`);
        },100);
      });
    },

    handleOrientationChange() {
        const that = this;

        var mql = window.matchMedia("(orientation: portrait)");
        mql.addListener(function(m) {
          if(that.$store.getters.isAuthed!==true || that.$route.name==='Gallery' || that.$route.name==='Tutorial' || that.$route.name==='Summary'){ return }
          if(m.matches) {
            that.$root.$emit('toggleFooter',true);
            //if(that.$route.name==='Loyalty Card') that.$router.go(-1);
          } else {
            that.$router.push('/site/'+that.$route.params.siteId+'/account/loyalty/card/lite');
          }
        });
    },

    swipeRight(){
      this.$store.commit("setNavOpen",false);
    },
    swipeLeft(){
      this.isLoading = true;
      this.to3 = setTimeout(()=>{
        this.isLoading = false
      },1000);
    },

    startAppVersionCheck(){

      this.checkAppVersion();
      //setInterval(()=>{
        //this.checkAppVersion();
      //},(1000*180));
    },

    checkAppVersion(){
      axios.get('/app-version').then((response)=>{
        if(response && !response.error){

          document.getElementById('app').style = {opacity:1};
          if(this.$route.meta.maintenancePage===true) this.$router.push("/select-site");
          this.doVersionCheck(response.data.version);

        }
      });
    },

    doVersionCheck(serverVersion){

      if(serverVersion==="3.0.0") return;

      if(App.versionCompare(serverVersion,this.$store.getters.version)){
        CDV.tap('medium');

        this.$confirm({
          title  :'Update Available',
          message: "There is an app update available, please update to continue.",
          button : {
            yes : 'OK'
          },
          callback: confirm => {
            if (confirm){
              CDV.tap('medium');
              this.$store.commit('setAppVersion',serverVersion);
              if(window.cordova){
                if(process.env.CORDOVA_PLATFORM && process.env.CORDOVA_PLATFORM.toLowerCase()==='ios'){
                  window.open('https://itunes.apple.com/gb/app/chilledpubs/id1041707140?mt=8','_system','clearcache=yes,clearsessioncache=yes,location=no,hardwareback=no,zoom=no,toolbarposition=top,fullscreen=no')
                }else if(process.env.CORDOVA_PLATFORM && process.env.CORDOVA_PLATFORM.toLowerCase()==='android'){
                  window.open("https://play.google.com/store/apps/details?id=uk.co.chilledpubs.app",'_system','clearcache=yes,clearsessioncache=yes,location=no,hardwareback=no,zoom=no,toolbarposition=top,fullscreen=no')
                }
              }else{
                window.location.reload(true)
              }


            }
          }
        });


      }


    },

    startQtyCheck(){
      this.$store.dispatch('getSiteQtys');
      setInterval(()=>{
        this.$store.dispatch('getSiteQtys');
      },(1000*60));
    },

  },

}
</script>


<style lang="scss" src="./assets/sass/style.scss?v=1"></style>
<style lang="tailwindcss" src="./assets/css/app.css?v=1"></style>