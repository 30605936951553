var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app",class:{
      "show-safe-padding"   : _vm.addSafePadding,
      "is-standalone"       : _vm.isStandalone,
      "extra-footer-padding": _vm.extraFooterPadding,
      "show-nav"            : _vm.isNavOpen,
      "is-cordova"          : _vm.isCordova,
      "has-waitTime"        : _vm.hasWaitTime,
      "is-loading"          : _vm.isLoading,
      "is-popstate"         : (_vm.popStateDetected)
    },staticStyle:{"opacity":"0"},attrs:{"id":"app","data-platform":_vm.platform}},[_c('vue-progress-bar'),_c('div',{directives:[{name:"touch",rawName:"v-touch:swipe.right",value:(_vm.swipeRight),expression:"swipeRight",arg:"swipe",modifiers:{"right":true}}],staticClass:"more-container"},[_c('transition',{attrs:{"name":_vm.transitionName}},[_c('router-view',{key:_vm.$route.fullPath})],1),_c('transition',{attrs:{"name":"accordionTiny"}},[(_vm.$store.getters.site && _vm.showFooter)?_c('Nav'):_vm._e()],1)],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.$store.getters.subsPopupData)?_c('ModPopup',{attrs:{"data":_vm.$store.getters.subsPopupData}}):_vm._e()],1),_c('transition',{staticClass:"fade"},[(_vm.$store.getters.image)?_c('Img',{attrs:{"id":_vm.$store.getters.image}}):_vm._e()],1),(!_vm.isPublicPage && _vm.$store.getters.site && !_vm.hideFooter)?_c('NavMore'):_vm._e(),(_vm.showATC)?_c('a-t-c',{attrs:{"pos":_vm.showATC}}):_vm._e(),_c('vue-confirm-dialog')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }