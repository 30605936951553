<template>
  <aside class="c-nav__more h-border--primary f-center">

    <div @click="$router.push('/site/'+$route.params.siteId+'/pre-home/').catch(()=>{});$store.commit('setNavOpen',false)" class="c-nav__site h-color--fill" data-color="primary" v-in-viewport.once v-html="site.icon"></div>

    <div class="f-center">
      <a class="social" @click="openS('facebook')"><Icon icon="facebook" mt="1" font-size="25" :inline="true"/></a>
      <a class="social" @click="openS('instagram')"><Icon icon="insta" mt="1" font-size="25" :inline="true"/></a>
    </div>

    <div v-if="status" class="bg-red mx-2 mb-0 f-cta1 text-white text-center rounded-full p-2" :class="{'!bg-green':status==='checked_in','!bg-grey':(status==='break_started' || status==='break_ended')}">
       {{(status)?status.replace('_',' ').replace('checked','clocked'):''}}
    </div>


    <div v-if="$store.getters.isAuthed && $store.getters.moduleActive('app.account.sameAgain')" class="c-nav__buttons h-color--bg" data-color="cream">
      <a @click="sameAgain()" class="o-cta c-menuItemFilter h-color--text f-cta1 h-marginBottom--5 h-marginTop--5 " style="padding: 5px 10px;" data-color="primary">Same Again?</a>
    </div>

    <div v-if="$store.getters.isAuthed && $store.getters.isTeamMember" class="c-nav__buttons h-color--bg" data-color="offcream">
      <a @click="goTo('/site/'+$route.params.siteId+'/management')" class="o-cta c-menuItemFilter h-color--text f-cta1 h-marginBottom--5 h-marginTop--5 " style="padding: 5px 10px;" data-color="primary">Chilled Team</a>
    </div>

    <a @click="goTo('/site/'+$route.params.siteId+'/home')"  class=" f-b3"><Icon icon="home" :inline="true" />Home</a>

    <a v-if="$store.getters.moduleActive('app.account') && $store.getters.isAuthed" @click="goTo('/site/'+$route.params.siteId+'/account')"  class=" f-b3"><Icon icon="account" :inline="true" />My Account</a>

    <a v-if="$store.getters.moduleActive('app.account.favorites') && $store.getters.isAuthed" @click="goTo('/site/'+$route.params.siteId+'/account/favorites')"  class=" f-b3"><Icon icon="favourites"  mt="1" :inline="true" />My Favorites</a>

    <a v-if="$store.getters.moduleActive('app.paybill')" @click="goToCheckPage()"  class=" f-b3"><Icon icon="orders" font-size="22" mt="3" :inline="true" />Pay My Bill</a>

    <a v-if="$store.getters.moduleActive('app.booking') && ($store.getters.site.active_booking_widget && $store.getters.site.active_booking_widget==='upsell')" @click="goTo('/site/'+$route.params.siteId+'/booking/home')"  class=" f-b3"><Icon icon="submit" font-size="22" mt="3" :inline="true" />Bookings</a>

    <a v-if="$store.getters.moduleActive('app.giftcards') && $store.getters.moduleActive('app.loyalty')" @click="goTo('/site/'+$route.params.siteId+'/giftcards')" class=" f-b3"><Icon icon="giftcard" font-size="16" mt="0" class="f-bold" :inline="true" />Gift Cards</a>

    <a v-if="$store.getters.moduleActive('app.tours')" @click="goTo('/site/'+$route.params.siteId+'/tours')" class=" f-b3"><Icon icon="wings"  font-size="20"  mt="1" :inline="true" />Flying Tours</a>

    <a @click="goTo('/site/'+$route.params.siteId+'/menu/25/menu-products')" class=" f-b3"><IconHoodie class="inline-block text-primary" />Merchandise</a>

    <a v-if="$store.getters.moduleActive('app.team')"  @click="openS('team')" class=" f-b3"><Icon icon="account" :inline="true" />Join our Team</a> <!--@click="goTo('/site/'+$route.params.siteId+'/team')" -->

    <a v-if="$store.getters.moduleActive('app.gallery')" @click="goTo('/site/'+$route.params.siteId+'/gallery')" class=" f-b3"><Icon icon="gallery" :inline="true" />Gallery</a>

    <a v-if="$store.getters.moduleActive('app.news')" @click="goTo('/site/'+$route.params.siteId+'/news')"  class=" f-b3"><Icon icon="news" :inline="true" />News</a>

    <a v-if="$store.getters.moduleActive('app.taxi')" @click="goTo('/site/'+$route.params.siteId+'/taxis')" class=" f-b3"><Icon icon="taxi" :font-size="22" :inline="true" />Local Taxis</a>


    <a v-if="$store.getters.moduleActive('app.zendDesk') && $store.getters.moduleActive('app.zendDesk.chat')" @click="openZend()" class=" f-b3"><Icon icon="contact" :inline="true" />Live Chat</a>

    <a v-if="$store.getters.moduleActive('app.zendDesk') && $store.getters.moduleActive('app.zendDesk.guide')" @click="openS('help')"  class=" f-b3"><Icon icon="help" :inline="true" />Help Centre</a> <!-- @click="goTo('/site/'+$route.params.siteId+'/help')" -->

    <a  v-if="$store.getters.moduleActive('app.tutorials')" @click="goTo('/site/'+$route.params.siteId+'/tutorials')" class=" f-b3"><Icon icon="tut" :inline="true" />Tutorials</a>


    
      <div class="c-nav__buttons">
        <router-link v-if="!$store.getters.isAuthed && $store.getters.moduleActive('app.account') && $store.getters.moduleActive('app.account.login')" :to="'/site/'+$route.params.siteId+'/login'" class="o-cta c-menuItemFilter h-color--text f-cta1 " style="padding: 5px 10px;" data-color="primary">Login</router-link>
        <router-link v-if="!$store.getters.isAuthed && $store.getters.moduleActive('app.account') && $store.getters.moduleActive('app.account.register')" :to="'/site/'+$route.params.siteId+'/register'"  class="o-cta c-menuItemFilter h-color--text f-cta1 " style="padding: 5px 10px;" data-color="primary">Create Account</router-link>
        <a @click="changePub()"  class="o-cta c-menuItemFilter h-color--bg h-color--border f-cta1  h-marginBottom--5" style="padding: 5px 10px;" data-color="primary"><span class="h-color--text" data-color="white">Change Pub</span></a>
      </div>


  </aside>
</template>

<script>

import Icon from "@/components/App/Icon";
import config from "@/config";
import CDV from "@/Libary/Cordova/CDV";
import IconHoodie from "@/components/Icons/IconHoodie.vue";
import axios from "axios";

export default {
  name: "NavMore",
  components: {
    IconHoodie,
    Icon,
  },
  data(){
    return {
      status:null
    }
  },
  mounted() {
    setTimeout(this.getUserStatus,60*1000);
  },
  computed: {
    isCordova(){
      return window.isCordova || process.env.NODE_ENV==='development'
    },
    site(){
      return this.$store.getters.site
    },
    tableNumber(){
      return this.$store.getters.tableNumber(true);
    },
    allowPayBill(){
      return this.$store.getters.allowPayBill();
    }
  },
  methods:{

      getUserStatus(){

        if(!this.$store.getters.isAuthed || !this.$store.getters.isTeamMember) return;

        axios.get('/appytap/get-user-status').then((response)=>{
          this.status = response.data.latest_status;
        });

      },
      goTo(page){
        this.$store.commit('setNavOpen',false);
        this.$router.push(page).catch(()=>{});
      },
      goToCheckPage(){
        if(this.allowPayBill===true){
          this.goTo('/site/'+this.$route.params.siteId+'/table/'+this.tableNumber+'/select');
        }else{
          this.goTo('/site/'+this.$route.params.siteId+'/table');
        }

      },
      changePub(){

        if(this.$store.getters.orderItemCount>0){

          CDV.notify('warning');

          this.$confirm({
            title: 'Change Pub',
            message: 'Changing pub will empty your basket. Are you sure you want to continue?',
            button: {
              no: 'No',
              yes: 'Yes'
            },
            callback: confirm => {
              if (confirm) {
                this.$router.push("/select-site");
              }
              this.$store.commit('setNavOpen',false)
            }
          })
        }else{
          CDV.notify('success');
          this.$router.push("/select-site");
          this.$store.commit('setNavOpen',false)
        }
      },
      openZend(){
        this.$store.commit('setNavOpen',false);
        window.zE.activate();
      },
      openS(type){

        let url;
        if(type==='facebook')  url = this.site.facebook;
        if(type==='instagram') url = this.site.instagram;
        if(type==='help')      url = config.getConfig("app.contact", "link");
        if(type==='team')      url = config.getConfig('app.team','link');

        CDV.logEvent("social", {type: this.$store.getters.site.code.toUpperCase()+' '+type});

        if(window.isCordova){
          window.cordova.InAppBrowser.open(url, '_blank', 'location=no,hardwareback=no,zoom=no,toolbarposition=top,fullscreen=no');
        }else{
          window.open(url,'_blank','location=no,hardwareback=no,zoom=no,toolbarposition=top,fullscreen=no,noopener')
        }

      },
      sameAgain(){

        CDV.logEvent("order", {type: this.$store.getters.site.code.toUpperCase()+' Same Again'});
        CDV.tap('medium');
        this.$store.dispatch('sameAgain');

      }
  }

};
</script>
