import _ from "underscore";
import config from "@/config";
import store from "@/store"

export class App {

    /**
     *
     * @param position
     * @param images
     * @param type
     * @returns {*}
     */
    static getImage(position,type,images){

        if(!images){ return; }

        return _.find(images, function(image) {
            if(type) return image.position===position && image.type===type
            return image.position===position
        });

    }

    static getFullImage(images){

        const imageSet = this.getImage("main","main",images);

        return config.getImageBase()+imageSet.original;
    }

    static getIcon(images){

        const icon =  _.find(images, function(image) {
            return image.type==="icon";
        });
        if(!icon){ return; }
        const imageBase = config.getImageBase();

        return imageBase+icon.original;

    }

    static getThumbnailSet(images){

        const imageSet = this.getImage("thumbnail","thumbnail",images);
        if(!imageSet){ return false }

        return this.formatSrcset(imageSet);

    }

    static getThumbnailBgSet(images){

        const imageSet = this.getImage("thumbnail","thumbnail",images);
        if(!imageSet){ return false }

        return this.formatBg(imageSet);

    }

    static getThumbnailMainSet(images){

        let imageSet = this.getImage("main","thumbnail",images);

        if(!imageSet){
            imageSet = this.getIcon(images)
            if(!imageSet){ return false; }
            return imageSet
        }

        return this.formatSrcset(imageSet);

    }

    static getMainSet(images){

        const imageSet = this.getImage("main","main",images);
        if(!imageSet){ return false }
        return this.formatSrcset(imageSet);

    }

    static getBackgroundImageSet(images){

        const imageSet  = this.getImage("main","main",images);
        if(!imageSet){ return }

        return this.formatBg(imageSet)

    }

    static getMenuItemImageSet(images,all=false){

        let imageSet  = this.getImage("main","main",images);

        if(!imageSet && all!==true){ return }

        if(!imageSet || (imageSet && !imageSet.aspect_ratio && imageSet.aspect_ratio<0.7)){
            imageSet  = this.getImage("main","thumbnail",images);
            if(!imageSet){ return }
        }

        return {
            format:imageSet.format,
            aspect_ratio:imageSet.aspect_ratio,
           // srcset:(imageSet.format==="jpg")?this.formatBg(imageSet):this.formatSrcset(imageSet)
            srcset:this.formatBg(imageSet)
        }

    }

    static formatBg(imageSet){

        const imageBase = config.getImageBase();
        const setStr = "url("+encodeURI(imageBase+imageSet.x1)+") 1x, url("+encodeURI(imageBase+imageSet.x2)+") 2x, url("+encodeURI(imageBase+imageSet.x3)+") 3x";
        return  "background-image:-webkit-image-set("+setStr+")";

    }

    static getVideoUrl(video){
        if(!video){ return null; }
        return config.getImageBase()+((video.url)?video.url:video);
    }

    static formatSrcset(imageSet){
        const imageBase = config.getImageBase();
        return  encodeURI(imageBase+imageSet.x1)+" 1x,"+encodeURI(imageBase+imageSet.x2)+" 2x,"+encodeURI(imageBase+imageSet.x3)+" 3x";
    }

    static formatPrice(price,force=false){

        if(!price && price!==0 && force!==true){ return null }

        const parts = parseFloat(price).toFixed(2).toString().split(".");
        let value = parseInt(parts[0]);

        value += ".<span class=\"c-price__pence\">"+parts[1]+"</span>"

        return "&pound;"+value;

    }

    static placeholder(){
        return "@/assets/img/svg/placeholders/"+store.getters.site.code+".svg";
    }

    static versionCompare(v1, v2, options) {
        var lexicographical = options && options.lexicographical,
            zeroExtend = options && options.zeroExtend,
            v1parts = v1.split('.'),
            v2parts = v2.split('.');

        function isValidPart(x) {
            return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
        }

        if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
            return NaN;
        }

        if (zeroExtend) {
            while (v1parts.length < v2parts.length) v1parts.push("0");
            while (v2parts.length < v1parts.length) v2parts.push("0");
        }

        if (!lexicographical) {
            v1parts = v1parts.map(Number);
            v2parts = v2parts.map(Number);
        }

        for (var i = 0; i < v1parts.length; ++i) {
            if (v2parts.length == i) {
                return 1;
            }

            if (v1parts[i] == v2parts[i]) {
                continue;
            }
            else if (v1parts[i] > v2parts[i]) {
                return 1;
            }
            else {
                return -1;
            }
        }

        if (v1parts.length != v2parts.length) {
            return -1;
        }

        return 0;
    }



}