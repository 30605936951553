import axios from "axios";
import router from "@/router";

import moment from "moment";

const state = {
  auth: (localStorage.getItem("vuex") && JSON.parse(localStorage.getItem("vuex")).auth) ? JSON.parse(localStorage.getItem("vuex")).auth.auth : null,
  isLoginError: false,
  authExpireDate: null,
};

const getters = {
  isAuthed: (state) => (state.auth ? true : false),
  access_token: (state) => state.auth.access_token,
  refresh_token: (state) => (state.auth && state.auth.refresh_token)?state.auth.refresh_token:null,
  isLoginError: (state) => state.isLoginError,
  authExpireDate: (state) => state.authExpireDate
};

const actions = {
  async socialLogin({ commit }, auth) {
    commit("setAuth", auth);
  },
  async logIn({ commit }, form) {
    axios
      .post("/login", form)
      .then((response) => {



        if (response && !response.error) {
          commit("setAuth", response.data);
          commit("setLoginError", false);
        } else {
          commit("setLoginError", true);
        }
      })
      .catch((err) => {
        console.log(err);
        commit("setLoginError", true);
      });
  },

  async forgottenPassword({ commit }, form) {
    axios.post("/password/email", form).then(response => {
        if (response && !response.error) {
          commit("setLoginError", null);

          this.$confirm({
            title   : 'Logout',
            message : response.data.success,
            button  : { yes:'Yes' },
          });

          const siteId = (router.history.current.params.siteId)?router.history.current.params.siteId:0;
          router.replace("/site/"+siteId+"/login");

        } else {
          commit("setLoginError", "There was an error");
        }
      })
      .catch(err => {
        if( err.data && err.data.error &&  err.data.error.message) commit("setLoginError", err.data.error.message);
      });
  },

  async register({ dispatch }, form) {
    await axios.post("/register", form).then((response) => {
      if (response && !response.error) {
        if(form.password) dispatch("logIn", { email: form.email, password: form.password });
      }
    });
  },

  async refreshAccess({ commit }) {
    console.log('refresh token');
    if (this.getters.isAuthed !== true) {
      console.log('refresh token: not authed');
      return;
    }
    console.log('refresh token: request');
    await axios.post("/refresh").then((response) => {
      console.log('refresh token: response');
      if (response && !response.error) {
        console.log('refresh token: no error',response.data);
        commit("setAuthOnly", response.data);
      }
    });
  },

  async registerPushToken(){

    if(this.getters.pushToken===null) {
      console.log('push token = null');
      return;
    }

    const data = {
      token:this.getters.pushToken
    };

    let guest = (!this.getters.isAuthed)?'/guest':''

    axios.post('/notifications/register'+guest,data).then((response)=>{
      if(response && response.data && response.data.success===true)axios.defaults.headers.common["X-P2F-PUSH-ID"] = response.data.push_token_id;
    })

  },

  async logOut({ commit }) {
    commit("logout");
    commit("setNavOpen", false);
  },
};

const mutations = {
  setAuth(state, auth) {
    state.auth = auth;
    state.authExpireDate = moment().add(auth.expires_in, "seconds").startOf().utc();
    console.log('SET AUTH',auth)
    this.dispatch("getUser");
    this.dispatch("getConfig");
    this.dispatch("registerPushToken");
  },

  setAuthOnly(state, auth) {
    state.auth = auth;
    state.authExpireDate = moment().add(auth.expires_in, "seconds").startOf().utc();
    //this.dispatch("getUser");
    //this.dispatch("getConfig");
  },

  setLoginError(state, isError) {
    state.isLoginError = isError;
  },


  logout(state) {
    state.isLoginError = false;
    state.auth = null;
    state.authExpireDate = null;
    state.data = {};
    this.dispatch("clearUser");
    //this.commit('clearAllCachedData');
    this.commit("clearOrder");
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
