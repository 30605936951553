import _ from "underscore";


export class Favorites {

    static cleanse(item){
        const cloned = JSON.parse(JSON.stringify(item));

        let filtered = _.pick(cloned, 'pos_id','name',"price","modifiers","parent",'uuid',"qty");
        filtered.price = null;
        _.each(filtered.modifiers,(modG)=>{
            modG.valid = false;
            _.each(modG.modifiers,(mod)=>{
                mod.price = null;
            });
        });
        return filtered;

    }

    static cleanseMany(items){
        let that = this;
        const clonedItems = JSON.parse(JSON.stringify(items));
        let cleansed = [];
        _.each(clonedItems,(item)=>{
            cleansed.push(that.cleanse(item))
        });
        return cleansed;
    }
}

