import axios from "axios";

const state = {
    config: (localStorage.getItem('vuex') && JSON.parse(localStorage.getItem('vuex')).config)?JSON.parse(localStorage.getItem('vuex')).config.config:null,
    mapsLoaded:false
};

const getters = {
    config  : (state) => state.config,
    mapsLoaded : (state) => state.mapsLoaded,
};

const actions = {

    async getConfig({commit}){

        await axios.get("/app-config").then(function(response){

            if (response && !response.error) commit("setConfig", response.data);
        });

    }

};

const mutations = {

    setConfig(state, config) {
        state.config = config;
    },

};

export default {
    state,
    getters,
    actions,
    mutations,
};