<template>
  <nav class="c-nav  h-evenlySpace" >

    <transition name="fade">
      <div v-if="waitTime!==null && waitTime!==0" class="c-nav__weightTime f-cta1 h-color--bg" data-color="primary">
        Approx wait on Food: <span v-text="waitTime"></span> mins
      </div>
    </transition>

    <a  @click="home()" class="c-nav__link" :class="{'router-link-active':$route.name==='Home'}">
      <div class="c-nav__icon ">
        <Icon icon="home"/>
      </div>
      <h5 class="c-nav__name f-b6 f-eqLH h-uppercase">Home</h5>
    </a>

    <router-link v-if="$store.getters.moduleActive('app.menu.food')" :to="'/site/'+$route.params.siteId+'/menu/food'" class="c-nav__link" :class="{'router-link-active':$route.params.type=='food'}">
      <div class="c-nav__icon">
        <Icon icon="food"/>
      </div>
      <h5 class="c-nav__name f-b6 f-eqLH h-uppercase">Food</h5>
    </router-link>

    <router-link v-if="$store.getters.moduleActive('app.menu.drink')" :to="'/site/'+$route.params.siteId+'/menu/drinks'"  class="c-nav__link" :class="{'router-link-active':$route.params.type=='drink'}">
      <div class="c-nav__icon">
        <Icon icon="drinks" font-size="17" mt="1" />
      </div>
      <h5 class="c-nav__name f-b6 f-eqLH h-uppercase">Drink</h5>
    </router-link>

    <router-link v-if="$store.getters.moduleActive('app.menu.search')" :to="'/site/'+$route.params.siteId+'/menu/search'" class="c-nav__link">
      <div class="c-nav__icon">
        <Icon icon="search" mt="1" />
      </div>
      <h5 class="c-nav__name f-b6 f-eqLH h-uppercase">Search</h5>
    </router-link>

    <a @click="toggleMore()" class="c-nav__link" :class="{'c-nav__more--active':isNavOpen===true}">
      <div class="c-nav__icon h-color--fill" data-color="darkgrey">
        <Icon icon="more" />
      </div>
      <h5 class="c-nav__name f-b6 f-eqLH h-uppercase">More</h5>
    </a>

  </nav>
</template>

<script>

import Icon from '@/components/App/Icon';
import CDV from "@/Libary/Cordova/CDV";

export default {
    name: 'Nav',
    data(){
      return {
        timeout:null
      }
    },
    components:{
      Icon,
    },
  computed:{
    isNavOpen(){
      return this.$store.getters.isNavOpen
    },
    waitTime(){
      return this.$store.getters.site.wait_time
    }
  },
  watch:{
    $route (){
      this.$store.commit("setNavOpen",false);
    }
  },
  methods:{


      home(){
        if(window.zE && window.zE.hide) window.zE.hide();
        this.$router.push('/site/'+this.$route.params.siteId+'/home').catch(()=>{});
      },
      toggleMore(){
          if(window.zE && window.zE.hide) window.zE.hide();
          CDV.tap('medium');
          if(this.$store.getters.isNavOpen){
            this.$store.commit("setNavOpen",false);
          }else{
            this.$store.commit("setNavOpen",true);
            if(this.timeout) clearTimeout(this.timeout);
          }
      },
    },

};
</script>
