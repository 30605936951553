<template>
  <div class="o-atc" data-color="primary" :class="{'o-atc--active':go}" :style="posStyle">
    <Placeholder :is-atc="true"/>
  </div>
</template>

<script>
import Placeholder from "@/components/App/Placeholder";
export default {
  name: 'ATC',
  props:['pos'],
  data(){
    return {
      go:false,
      to:null,
      to2:null,
    }
  },
  components:{
    Placeholder
  },
  computed:{
    posStyle(){
      return 'top:'+this.pos.top+'px;left:'+this.pos.left+'px';
    }
  },
  beforeDestroy() {
    clearTimeout(this.to);
    clearTimeout(this.to2);
  },
  mounted() {


      this.to = setTimeout(()=>{
        this.go=true;
      },50);
      this.to2 = setTimeout(()=>{
        this.$root.$emit('showATC',false);
      },1*1000);
  }
}

</script>
