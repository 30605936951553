<template>
  <div class="spinner" :class="{'spinner-static mb-[0px] relative z-[100]':isStatic,'h-marginBottom--0 h-marginTop--5 h-marginLeft--10 h-marginRight--5':mb===false,'w-[36px]':small}">
    <div v-if="site" class="h-color--fill relative z-[90]" data-color="primary" v-html="site.icon"></div>
  </div>
</template>

<script>

export default {
  name: 'Spinner',
  props:['isStatic','mb','small'],
  computed:{
    site(){
      return this.$store.getters.site
    }
  }
}

</script>
