<template>
  <div :class="{'h-inlineB':inline,'o-icon':!auto}" data-color="primary">
    <div :class="'icon-icon_'+icon" :style="style" class="h-color--text"></div>
  </div>
</template>

<script>

export default {
  name: 'Icon',
  props:['icon','inline','fontSize',"auto",'mt',"colour"],
  computed:{
    style(){
      let style = '';
      if(this.fontSize) style+='font-size:'+this.fontSize+'px;';
      if(this.mt)       style+='margin-top:'+this.mt+'px;';
      if(this.colour)    style+='color:'+this.colour+';';
      return style;
    }
  }
}

</script>
