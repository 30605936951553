<template>
  <div class="c-image h-color--bg" data-color="cream">
    <pinch-zoom v-if="image">
      <img :src="image" @load="isLoaded=true" :class="{'in-viewport':isLoaded}" class="a-inview a-fadeIn a-scaleUp" />
    </pinch-zoom>

    <Spinner v-if="isLoaded===false" />

    <a @click="goBack" class="o-back o-back--fixed h-color--text" style="padding: 10px; height: 37px" data-color="primary">
      <span class="o-back__icon h-color--fill" data-color="primary">
          <IconBack/>
      </span>
      <span class="o-back__text h-uppercase f-b4 f-bold">Back</span>
    </a>
  </div>
</template>

<script>
import config from "@/config";
import IconBack from "@/components/Icons/IconBack.vue";
import Spinner from "@/components/App/Spinner.vue";

export default {
  name: 'Img',
  props:['id'],
  components:{
    Spinner,
    IconBack
  },
  data(){
    return {
      key:"image",
      isLoaded:false
    }
  },
  mounted() {
    this.$store.dispatch("request",{ verb:'get', url:this.endpoint, key:this.key });
  },
  computed: {
    endpoint() {
      return config.endpoint(this.key, {imageId:this.id});
    },
    image() {
      let image = (this.$store.state.data.data[this.endpoint]) ? this.$store.state.data.data[this.endpoint] : null;
      if(!image){ return image }
      return config.getImageBase()+image.original;
    },
  },
  methods:{
    goBack(){
      this.$store.commit('image',null);
    }
  }
}

</script>
