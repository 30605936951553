import axios from "axios";
import Vue from "vue";
import _ from "underscore";

const state = {
    customer: (localStorage.getItem('vuex') && JSON.parse(localStorage.getItem('vuex')).config)?JSON.parse(localStorage.getItem('vuex')).config.customer:null,
};

const getters = {
    customer   : (state) => state.customer,
    loyaltyIsolated:(state) => state.customer.loyalty_is_brand_isolated,
    moduleActive: (state) => (key) => {

        if(!state.customer) return true;

        const module = _.find(state.customer.app_module,(module)=>{
            return module.key === key;
        });

        if(!module) return true; //todo does this work properly... i had it as true. -- NO IT DOES NOT....

        return module.is_active;
    }
};

const actions = {

    async getCustomer({commit}){

        await axios.get("/customer").then(function(response){
            if (response && !response.error) commit("setCustomer", response.data);
        });

    },

};

const mutations = {

    setCustomer(state, customer) {
        Vue.set(state,'customer',customer);
    },

};

export default {
    state,
    getters,
    actions,
    mutations,
};