<template>
  <div class="c-popup__container">
    <div class="c-popup c-popup--mods c-popup--noSidePadding h-paddingBottom--20">
      <h1 class="f-h1" v-text="title"></h1>
      <hr class="o-hr h-marginTop--10" />

      <ModifierGroup v-for="(item) in modMods" v-bind:key="item.id" :index="0" :modifier-group="item" :is-sub="true" :is-swap="true" :parent-modg-id="data.parentModgId" :parent-mod-id="data.parentModId" />

      <a @click="close()" class="o-close f-eqLH f-b4 " data-color="primary">&times;</a>

    </div>
  </div>
</template>

<script>

import config from "@/config";
import CDV from "@/Libary/Cordova/CDV";

export default {
  name: 'ModPopup',
  props:['data'],
  data(){
    return {
      key:'siteModifierModsPopup'
    }
  },
  components:{
    ModifierGroup: () => import('@/components/Menu/ModifierGroups/ModifierGroup'),
  },
  mounted(){
    this.$store.dispatch("request",{ verb:'get', url:this.endpoint, key:this.key });
  },
  computed:{
    title(){
      return 'Swap '+this.data.subParentName.replace('No ',"")+" for";
    },
    endpoint(){
      return config.endpoint(this.key,{modifierId:this.data.parentModLId,siteId:this.$route.params.siteId});
    },
    modMods(){
      return (this.$store.state.data.data[this.endpoint])?this.$store.state.data.data[this.endpoint]:[];
    },
  },
  methods:{
    close(){
      CDV.tap('medium');
      this.$store.commit('closeSubsPopup');
    }
  }
}

</script>
