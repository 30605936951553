import axios from "axios";
import Vue from "vue";
import moment from 'moment'
import config from "@/config";
import store from "@/store";
import _ from "underscore";
//import router from "@/router";

const state = {
    isBusy:false,
    data: {},
    dataExpiry: {},
    transitionName:"fade",
    isNavOpen:false,
    lastPagePosition: {},
    lastPageFilters:{},
    secFilter:null,
    popup:null,
    popupSeen:{},
    search:null,
    popupsSeen:{},
    subsPopupData:null,
    image:null,
    video:null,
    goingBack:false,
    doorsSeen:false,
    addedFavorites:[],
    sharedBasketItems:[],
    qtys:{},
    failedSalesItemIds:[],
    checkUuid:{}
};

const getters = {
    isBooted          :(state) => (state.isBooted)?state.isBooted:false,
    popup            : (state) => state.popup,
    popupSeen        : (state) => state.popupSeen,
    video            : (state) => state.video,
    doorsSeen        : (state) => state.doorsSeen,
    addedFavorites   : (state) => state.addedFavorites,
    image            : (state) => state.image,
    subsPopupData    : (state) => state.subsPopupData,
    secFilter        : (state) => state.secFilter,
    lastPageFilters  : (state) => state.lastPageFilters,
    lastPagePosition : (state) => state.lastPagePosition,
    transitionName   : (state) => state.transitionName,
    isNavOpen        : (state) => state.isNavOpen,
    goingBack        : (state) => state.goingBack,
    search           : (state) => state.search,
    sharedBasketItems: (state) => state.sharedBasketItems,
    qtys             : (state) => state.qtys,
    checkUuid        : (state) => (checkId)=> {
        return (state.checkUuid[checkId])?state.checkUuid[checkId]:null;
    },
    openTablesToken  : (state) => ()=> {
        if (!state.openTablesToken) return null;
        let expiry = config.getConfig('app.tablenumbers', 'qrExpiry')
        if (expiry === null) expiry = 3;
        if (moment(state.openTablesToken.date).isAfter(moment().subtract(expiry, 'hours'))) return state.openTablesToken.token
        return null;
    },

    accessUUID  : (state) => ()=> {
        if (!state.accessUUID) return '';
        let expiry = config.getConfig('app.tablenumbers', 'qrExpiry')
        if (expiry === null) expiry = 3;
        if (moment(state.openTablesToken.date).isAfter(moment().subtract(expiry, 'hours'))) return state.accessUUID.uuid
        return null;
    },

    tableNumber      : (state) => (numberOnly)=>{
      if(!state.tableNumber) return null;

      let expiry = config.getConfig('app.tablenumbers','qrExpiry')
      if(expiry===null) expiry = 3;

      if(moment(state.tableNumber.date).isAfter(moment().subtract(expiry,'hours'))){
      //if(moment().isSame(state.tableNumber.date, 'day')){
          if(numberOnly===true){
              const parts = state.tableNumber.table.split('-');
              return parts[1];
          }else{
              return state.tableNumber.table;
          }
      }
      return null;
    },

    allowPayBill : (state,getters)=>()=>{

        if(getters.moduleActive('app.paybill')!==true) return false;

        const tableNumber = getters.tableNumber(true);

        if(!tableNumber) return false;
        let found = null;
        _.each(getters.site.site_area,(v)=>{
            if(v.number===parseInt(tableNumber[0])) found = v;
        });

        return (found)?found.allow_paybill:false;

    },

    allowOpenTables : (state,getters)=>()=>{

        if(getters.moduleActive('app.opentables')!==true) return false;
        if(!getters.openTablesToken()) return false;

        const tableNumber = getters.tableNumber(true);

        if(!tableNumber) return false;

        let found = null;
        _.each(getters.site.site_area,(v)=>{
            if(v.number===parseInt(tableNumber[0])) found = v;
        });
        return (found)?found.allow_opentables:false;

    },

    waitTime        : (state) => () =>{
        const waitTime =  _.find(state.qtys,(val,plu)=>{
            if(plu==config.getConfig('app.waitTime','PLU')) return val;
        });
        return (waitTime)?waitTime:null;
    },
    failedItems      : (state) => state.failedSalesItemIds,


    addingQty: (state) => (plu) =>{
        let qty = (state.qtys[plu] || state.qtys[plu]===0)?state.qtys[plu]:null;
        if(qty===null) return 99999;
        let orderQtys = store.getters.orderQTYsByPLU();
        if(orderQtys[plu]) qty = (qty - orderQtys[plu])
        return qty;
    },

    editingQty: (state) => (plu,uuid) =>{
        let qty = (state.qtys[plu] || state.qtys[plu]===0)?state.qtys[plu]:null;
        if(qty===null) return 99999;
        _.each(store.getters.order.items,(item)=>{
            if(item.uuid===uuid || item.pos_id!==plu) return
            if(item.stockLevelError===true && item.availableStock===item.qty) return;
            if(item.stockLevelError===true && item.availableStock<item.qty){
                qty = qty-item.availableStock;
            }else{
                qty = qty-item.qty;
            }
        });
        return qty;
    },

    editingModQty: () => (plu,uuid) =>{

        let qty = (state.qtys[plu] || state.qtys[plu]===0)?state.qtys[plu]:null;
        if(qty===null) return 99999;

        _.each(store.getters.order.items,(item)=>{
            if(item.uuid===uuid) return;
            if(item.stockLevelError===true && item.availableStock<=item.qty) return; //
            _.each(item.modifiers,(modG)=>{
                _.each(modG.modifiers,(mod)=>{
                    if(mod.pos_id!==plu) return;
                    if(mod.stockLevelError===true) return;
                    qty = qty-(mod.qty*item.qty);
                });
            });
        });

        return qty;

    },

    expiry : (state)=>{
        return state.dataExpiry
    }

};

const actions = {

    async getSiteQtys({commit}){

        if(!this.getters.site || !this.getters.site.id){ return; }
        await axios.post('/site/'+this.getters.site.id+'/qtys',{orderUuid:this.getters.orderUuid}).then((response)=>{
            if (response && !response.error) commit("setSiteQtys", response.data);
        });

    },

    setTransitionName({commit},transitionName){
        commit("setTransitionName",transitionName)
    },

    async request({ commit }, { verb, url, key=null, data }) {

        if(this.getters.expiry[url] && _.contains(config.noCache,key)!==true){
            if(moment().subtract(15, 'minutes').isBefore(this.getters.expiry[url])){ return; }
        }

        //if(verb==='get') url = url+'?api_token='+process.env.VUE_APP_API_TOKEN;
        //if(verb==='post' || verb==='put') data.api_token = process.env.VUE_APP_API_TOKEN;

        if(window.CP)  window.CP.$Progress.start();

        key;
        commit("isBusy", true);
         return await new Promise((resolve, reject) => {

            axios({
                method : verb,
                url    : url,
                data   : (data)?data:null
            })
            .then((response) => {
                    if(response && !response.error){
                        window.CP.$Progress.finish();
                        commit("isBusy", false);
                        commit("setData", { key:url,data:response.data});
                        if(key==='site') commit("setSite", response.data);
                        resolve(response);
                    }else{
                        window.CP.$Progress.fail()
                    }
                }).catch((error) => {
                    commit("isBusy", false);
                window.CP.$Progress.fail()
                    reject(error);
                });
        });
    },

};

const mutations = {

    setFailedSalesItems(state,failedItems){

        if(_.isArray(failedItems)){
            state.failedSalesItemIds = _.pluck(failedItems, 'SalesItemId');
        }else{
            state.failedSalesItemIds = [];
        }
    },

    setSearch(state,value){

        let secondFilter = '';

        if(value.isVeg)   secondFilter += '|Vegetarian|';
        if(value.isVegan) secondFilter += '|Vegan|';
        if(value.isGF)    secondFilter += '|Gluten Free|';

        if(secondFilter==='') secondFilter = null;

        this.commit('setSecFilter',secondFilter);

        state.search = value
    },

    goingBack(state,value){
        state.goingBack = value
    },

    isBusy(state,value){
        state.isBusy = value
    },

    setTransitionName(state,value){
        state.transitionName = value
    },

    setLastPagePosition(state,value){
        state.lastPagePosition[value.path] = value.scrollPosition
    },

    setLastPageFilter(state,filter){
        state.lastPageFilters[filter.path] = {value:filter.value,index:filter.index};
    },

    setSecFilter(state,filter){
        state.secFilter= (filter)?filter.toLowerCase():null;
    },

    removeLastPagePosition(state,value){
        Vue.set(state.lastPagePosition, value.path, 0);
    },

    setData(state,value){
        Vue.set(state.data, value.key, value.data);
        Vue.set(state.dataExpiry, value.key,moment().valueOf());
    },

    setSiteQtys(state,data){
        Vue.set(state, 'qtys', data);
        this.commit('checkBasketQtys');
    },

    clearAllCachedData(state){
        state.data = null;
        state.dataExpiry = null;
        state.data = {};
        state.dataExpiry = {};
        state.qtys = {};
    },

    setNavOpen(state, isNavOpen) {
        state.isNavOpen = isNavOpen;
    },

    setPopupSeen(state,type){
        state.popupSeen[type] = true
    },

    closePopup(state){
        state.popup = null
    },

    setSubsPopupData(state,data){
        state.subsPopupData = data
    },

    closeSubsPopup(state){
        state.subsPopupData = null
    },

    image(state,id){
        state.image = id
    },

    video(state,status){
        state.video = status
    },

    doorsSeen(state,doorsSeen){
        state.doorsSeen = doorsSeen
    },

    addedFavorites(state,uuid){
        state.addedFavorites.push(uuid)
    },

    setSharedBasketItems(state,sharedBasketItems){
        state.sharedBasketItems = sharedBasketItems
    },

    isBooted(state){
        state.isBooted = true
    },

    checkUuid(state,data){
        state.checkUuid[data.checkId] = data.uuid;
    },

    clearCheckUuids(state){
        state.checkUuid = {};
    },

    setTableNumber(state,tableNumber) {

        state.tableNumber = {
            table : tableNumber,
            date  : moment().format()
        }

    },

    setOpenTablesToken(state,openTablesToken) {

        state.openTablesToken = {
            token : openTablesToken,
            date  : moment().format()
        }

    },

    setAccessUUID(state,uuid) {

        state.accessUUID = {
            uuid : uuid,
            date  : moment().format()
        }

    },

    clearOpenTablesToken(state){
        state.accessUUID      = null;
        state.openTablesToken = null;
    }

};

export default {
    state,
    getters,
    actions,
    mutations,
};

