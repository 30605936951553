import _ from "underscore";

export class Orders {

    /**
     *
     * @param name
     * @returns {string}
     */
    static makeTag(name){
       return name.trim().toLowerCase().split(' ').join('').split('/').join('').replace('?','');
   }

    /**
     *
     * @param menuItem
     * @returns {*}
     */
   static setOrderItem(menuItem){

       const that = this;
       let defaultMods = {};
       let modGs = menuItem.modifier_group;
       _.each(modGs,(modG)=>{

            let defaultMod = _.find(modG.modifier,(mod)=>{
                if(mod.image_tag && modG.pivot.default_modifier_tag!==null) {
                    // TODO remember this typo fix
                    if(modG.pivot.default_modifier_tag==='lemoande') modG.pivot.default_modifier_tag = 'lemonade';
                    const tag = modG.pivot.default_modifier_tag
                    return (that.makeTag(mod.image_tag.replace("("+mod.pos_id+")",''))) === (that.makeTag(tag));
                }
            });

            if(!defaultMod && modG.pivot.minimum===1 && modG.pivot.maximum===1 && (modG.display_type==="Slider" || modG.display_type==="Carousel")){ //modG.pivot.minimum===1 &&
                defaultMod = modG.modifier[0];
            }

           let mods = (defaultMod)?[Orders.cleanseModifier(defaultMod)]:[];

           let isValid;
           if(modG.display_type!=='QuantityX'){
               isValid = (mods.length>=modG.pivot.minimum && mods.length<=modG.pivot.maximum);
           }else{
               const totalQty = _.reduce(mods, function (memoizer, mod) {
                   return memoizer + mod.qty;
               });
               isValid = (totalQty>=modG.pivot.minimum && totalQty<=modG.pivot.maximum);
           }

           defaultMods[modG.id] = {
               modifierGType     : modG.display_type,
               modifierGroupId   : modG.id,
               modifierGroupName : modG.name,
               valid             : isValid,
               modifiers         : mods
           };

       });

       let cleansed = _.pick(menuItem, "id","uuid",'pos_id',"itemType",'name','display_name',"price","qty","images","default_image","coursing","modifier_group","recipe","submenuId","menuId",'free_text');
           cleansed.modifiers = defaultMods;

       return cleansed;

   }

    /**
     *
     * @param menuItem
     * @returns {*}
     */
   static cleanseSalesItem(menuItem){
       return _.pick(menuItem, "id",'pos_id','name','display_name',"price","images","default_image","coursing",'submenuId','menuId');
   }

    /**
     *
     * @param modifiers
     * @returns {*[]}
     */
   static cleanseModifiers(modifiers){

       let cleansed = [];
       _.each(modifiers,(mod)=>{
           const picked = this.cleanseModifier(mod);
           if(picked) cleansed.push(picked);
       });

       return cleansed;
    }

    /**
     *
     * @param mod
     * @returns {*}
     */
    static cleanseModifier(mod){

        let picked =  _.pick(mod, 'pos_id','id', 'name','display_name',"price","qty","Quantity","quantity","parent",'free_text');

        picked.name = picked.name.replace("("+picked.pos_id+")",'');
        if(!picked.qty) { picked.qty = 1 }

        return picked;

    }

    /**
     *
     * @param modifiers
     * @returns {*[]}
     */
    static cleanseRecentOrderMods(modifiers){

       let cleansed = [];
        _.each(modifiers,(mod)=>{
            //mod.pos_id        = mod.pos_id
            mod.name          = mod.name.replace("("+mod.pos_id+")",'');
            cleansed.push(mod);
        });
        return cleansed;

    }

    /**
     *
     * @param orderItems
     * @returns {{puddings: *[], mains: *[], drinks: *[], starters: *[]}}
     */
    static formatCoursing(orderItems){

        let formatted = {
            starters : [],
            mains    : [],
            puddings : [],
            drinks   : []
        }

        _.each(orderItems,(item)=>{

            if(item.modifiers){
                let coursingMod =  _.filter(item.modifiers, (mod) => {
                    return (mod.modifierGroupName && mod.modifierGroupName.includes("Item Routing"));
                });
                if(coursingMod.length>0 && coursingMod[0].modifiers.length>0){
                    let setCourse = coursingMod[0].modifiers[0].name
                    if(setCourse.includes("With Starter")){
                        formatted.mains.push(item);
                        return;
                    }
                    if(setCourse.includes("With Main")){
                        formatted.mains.push(item);
                        return;
                    }
                    if(setCourse.includes("With Dessert") || setCourse.includes("With Pudding")){
                        formatted.puddings.push(item);
                        return;
                    }
                }
            }

            if(item.coursing){

                let starters = _.filter(item.coursing.modifier, (mod) => {
                    return (mod.name.includes("With Starter"));
                });
                if(starters.length===0){
                    if(!item.initialCourse) item.initialCourse = "starters";
                    formatted.starters.push(item);
                    return;
                }
                let main = _.filter(item.coursing.modifier, (mod) => {
                    return (mod.name.includes("With Main"))  ;
                });
                if(main.length===0){
                    if(!item.initialCourse) item.initialCourse = "mains";
                    formatted.mains.push(item);
                    return;
                }
                let pudding = _.filter(item.coursing.modifier, (mod) => {
                    return (mod.name.includes("With Dessert") || mod.name.includes("With Pudding"));
                });
                if(pudding.length===0){
                    if(!item.initialCourse) item.initialCourse = "puddings";
                    formatted.puddings.push(item);
                    return;
                }

            }else{
                if(!item.initialCourse) item.initialCourse = "drinks";
                formatted.drinks.push(item);
            }

        });

        console.log(formatted)

        return formatted;

    }

    static setCoursingToOrderItems(coursing){

        let final = []
        _.each(coursing,(items,course)=>{
            _.each(items,(item)=>{

                if(!item.modifiers) item.modifiers = {};

                let courseMod = null;
                let mods = (item.coursing)?item.coursing.modifier:null;
                if(mods && course==='starters'){
                    courseMod = _.find(mods,(mod)=>{ return mod.name.includes("With Starter"); });
                }else if(mods && course==='mains'){
                    courseMod = _.find(mods,(mod)=>{ return mod.name.includes("With Main"); });
                }else if(mods && course==='puddings'){
                    courseMod = _.find(mods,(mod)=>{ return (mod.name.includes("With Dessert") || mod.name.includes("With Pudding")); });
                }

                if(courseMod && item.initialCourse!==course){
                    courseMod.qty = 1;
                    courseMod.price = 0;
                    item.modifiers[item.coursing.id] = {
                        modifierGroupId   : item.coursing.id,
                        modifierGroupName : item.coursing.name,
                        modifiers         : [Orders.cleanseModifier(courseMod,item.coursing.id)],
                        valid             : true
                    }
                }else if (item.coursing && item.modifiers[item.coursing.id]){
                    delete item.modifiers[item.coursing.id];
                    if(_.isEmpty(item.modifiers)) delete item.modifiers;
                }
                final.push(item)
            });
        });

        console.log(final);

        return final;

    }

    /**
     *
     * @param basket
     * @param siteDba
     * @param selectedAreaId
     * @returns {boolean}
     */
    static checkDBA(basket,siteDba,selectedAreaId){

        let hasDBA = false;
        _.each(basket,(item)=>{
            let itemAreaDBA = _.find(siteDba,(dba)=>{
                return (dba.menu_id===item.menuId && dba.number===selectedAreaId);
            });
            if(itemAreaDBA){
                hasDBA = true;
                item.hasDBA = true;
            }
        });
        return hasDBA;

    }

    /**
     *
     * @param userMenuId
     * @param site
     * @returns {null|*[]}
     */
    static checkSubmenuDBA(userMenuId, site){

        if(!site.dba || (site.dba && site.dba.length===0)) return null;

        let menuDBA = [];
        _.each(site.dba,(dba)=>{
            if(dba.menu_id===parseInt(userMenuId)) menuDBA.push(dba.area_name);
        });
        if(menuDBA.length===0) return null;

        return menuDBA;

    }



}

